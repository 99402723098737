import IConfigurationService from './IConfigurationService'
import IConfig from '../config/IConfig'
import {merge} from 'lodash-es'
import LocalStorageService from './LocalStorageService'

export default class LocalConfigurationService implements IConfigurationService {
  private readonly localStorageService: LocalStorageService<IConfig>
  private readonly baseConfig: IConfig

  public constructor(baseConfig: IConfig) {
    this.baseConfig = baseConfig
    this.localStorageService = new LocalStorageService<IConfig>('config')
  }

  getConfiguration(): IConfig {
    return merge({}, this.baseConfig, this.localStorageService.getItem() || {})
  }

  setConfiguration(config: IConfig) {
    this.localStorageService.setItem(config)
  }
}
