import React from 'react'
import {useRouteMatch} from 'react-router'
import {OptionsType} from 'react-select'
import ISite, {SiteType} from '../../../../domain/ISite'
import useLocalization from '../../../../hooks/useLocalization'
import useStore from '../../../../hooks/useStore'
import useTheme from '../../../../hooks/useTheme'
import DottedIconButtonSelect from '../../../IconButton/DottedIconButtonSelect'
import Loader from '../../../Loader'
import SmartOption, {ISmartOption} from '../../../MultiSelect/SmartOption'
import NoResults from '../../../NoResults'
import Table, {IColumn} from '../../../Table'
import {useSetAtom} from 'jotai'
import {refetchSitesAtom} from 'atoms/portfolioSites'
import {Checkbox, createTheme, ThemeProvider} from '@mui/material'
import Link from 'components/Link'
import {LinkButton} from 'components/Button'
import Box from 'components/Box'

interface IProps extends React.PropsWithChildren {
  sites: ISite[]
  siteType: SiteType
  isLoading: boolean
  suffix?: string
  selectedSites: number[]
  setSelectedSite: (id: number) => void
  setAll: () => void
}

const SiteListNew: React.FC<IProps> = ({sites, isLoading, suffix, selectedSites, setSelectedSite, setAll}) => {
  const {siteStore, purchaseGroupStore} = useStore()
  const {translate} = useLocalization()
  const {url} = useRouteMatch()
  const refetchSites = useSetAtom(refetchSitesAtom)
  const theme = useTheme()

  if (isLoading) {
    return <Loader />
  }

  if (!sites || sites.length === 0) {
    return <NoResults>{translate('We are sorry. We did not find any sites matching your criteria.')}</NoResults>
  }

  const handleDelete = async (e, site) => {
    if (window.confirm(translate('Do you want to delete site %s?', site.name))) {
      await siteStore.deleteSite(site.id)
      // deleting a site might also delete the related purchase group, see https://renewabl.atlassian.net/browse/WP2-747
      await purchaseGroupStore.clearItems()
      await purchaseGroupStore.loadCurrentPartyPurchaseGroups()
      refetchSites()
    }
  }

  const muiTheme = createTheme({
    palette: {
      primary: {
        main: theme.colors.primary,
      },
    },
  })

  return (
    <ThemeProvider theme={muiTheme}>
      <Table<ISite>
        tableName={translate('Sites')}
        data={sites as ISite[]}
        columns={[
          {
            title: (
              <Checkbox
                checked={selectedSites.length === sites.length}
                indeterminate={selectedSites.length > 0 && selectedSites.length < sites.length}
                onChange={setAll}
              />
            ),
            disableLink: true,
            sortable: false,
            accessor: (site: ISite) => site.id,
            render: (id: number) => (
              <Box style={{cursor: 'pointer'}} onClick={() => setSelectedSite(id)}>
                <Checkbox
                  color="primary"
                  checked={selectedSites.includes(id)}
                  onChange={() => setSelectedSite(id)}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </Box>
            ),
          },
          {
            title: translate('Name'),
            accessor: (site: ISite) => site,
            render: (site: ISite) => (
              <Link to={`${url}/edit/${site.id}${suffix ? suffix : ''}`}>
                <LinkButton style={{justifyContent: 'left', whiteSpace: 'nowrap'}} pad={{left: 0}}>
                  {site.name}
                </LinkButton>
              </Link>
            ),
          },
          {
            title: translate('Meter ref'),
            accessor: (site: ISite) => site.meterId,
          },
          {
            title: translate('Region'),
            accessor: (site: ISite) => site.location?.addressSubdivision,
          },
          {
            title: 'Action',
            disableLink: true,
            accessor: (site: ISite) => site,
            render: (siteId, item) => (
              <div>
                <DottedIconButtonSelect
                  hideIndicators
                  components={{Option: SmartOption}}
                  options={
                    [
                      {
                        value: 'Edit',
                        to: `${url}/edit/${item.id}${suffix ? suffix : ''}`,
                        label: translate('Edit'),
                      },
                      // { // Removed until BE is done fixing deletion
                      //   value: 'Delete',
                      //   onClick: e => handleDelete(e, item),
                      //   label: translate('Delete'),
                      //   color: theme.colors.error,
                      // },
                    ] as OptionsType<ISmartOption>
                  }
                />
              </div>
            ),
          },
        ]}
      />
    </ThemeProvider>
  )
}

export default SiteListNew
