import React from 'react'
import ISite, {SiteType} from '../../../../domain/ISite'
import useLocalization from '../../../../hooks/useLocalization'
import useStore from '../../../../hooks/useStore'
import Form from '../../../Form'
import {useHistory, useRouteMatch} from 'react-router'
import Page from '../../../Page'
import FormSubmitButton from '../../../Form/FormSubmitButton'
import InnerScroll from '../../../InnerScroll'
import Heading from '../../../Heading'
import Box from '../../../Box'
import SiteMap from '../../Map/SiteMap'
import styled from 'styled-components'
import SiteFormContentNew from './Form/SiteFormContentNew'
import DottedIconButtonSelect from '../../../IconButton/DottedIconButtonSelect'
import LocationSearch from './LocationSearch'
import ITheme from '../../../../theme/ITheme'
import SiteChart from './SiteChart'
import useTheme from '../../../../hooks/useTheme'
import SmartOption from '../../../MultiSelect/SmartOption'
import {isNil} from 'lodash-es'
import IParty from '../../../../domain/IParty'
import {useSetAtom} from 'jotai'
import {refetchSitesAtom} from 'atoms/portfolioSites'

const LocationBox = styled(Box)`
  pointer-events: all;
  color: ${props => (props.theme as ITheme).colors.secondary};
`

interface IProps extends React.PropsWithChildren {
  site?: ISite
  siteType?: SiteType
  siteParty?: IParty
}

const SiteEditPage: React.FC<IProps> = ({site, siteType, siteParty}) => {
  const {translate} = useLocalization()
  const {siteStore, alertStore} = useStore()
  const history = useHistory()
  const refetchSites = useSetAtom(refetchSitesAtom)
  const portfolioRoute = useRouteMatch({path: '/:role/portfolio/sites'})
  const alternativeRoute = useRouteMatch()
  const theme = useTheme()

  const handleSubmit = async ({file, loadType, ...updatedSite}: ISite & {file?: File; loadType: string}) => {
    const savedSite = await siteStore.saveSiteWithoutTimeseries({...site, ...updatedSite}, siteParty)
    refetchSites()

    try {
      await siteStore.saveTimeseries(savedSite.id, file, loadType)
      alertStore.addSuccess(translate('Successfully saved %s', updatedSite.name))
      history.push(portfolioRoute ? portfolioRoute.url : alternativeRoute.url)
    } catch (e) {
      console.error(e)
      alertStore.addError(translate('Failed to upload %s', 'Profile data'), e?.correlationId, e?.message)
      history.push(`${portfolioRoute ? portfolioRoute.url : alternativeRoute.url}/${savedSite.id}`)
    }
  }

  const handleDelete = () => {
    if (window.confirm(translate('Do you want to delete site %s?', site.name))) {
      siteStore.deleteSite(site.id)
    }
  }

  return (
    <Form onSubmit={handleSubmit} fillHeight defaultValues={site} submissionFeedback={null}>
      <Page
        title={site ? site.name : translate('Add site')}
        description={translate('Manage your sites')}
        corner={
          <Box gap={2}>
            <FormSubmitButton>{translate('Save')}</FormSubmitButton>
            {site && (
              <DottedIconButtonSelect
                components={{Option: SmartOption}}
                options={
                  [
                    {
                      value: 'Delete',
                      onClick: handleDelete,
                      label: translate('Delete'),
                      color: theme.colors.error,
                    },
                  ] as any /* needed to override the library's type error */
                }
              />
            )}
          </Box>
        }
        aside={
          <SiteMap
            title={site ? translate('Site address') : translate('Add site address')}
            sites={site ? [site] : []}
            headerChildren={
              <LocationBox margin={{vertical: 1, horizontal: 3}}>
                {/* FIXME when adding new site view, the map is not updated on search result */}
                <LocationSearch location={site?.location} required={isNil(site?.location)} />
              </LocationBox>
            }
          />
        }
      >
        <InnerScroll noRightPad>
          {site && (
            <>
              <Heading margin={{bottom: 2}}>
                {translate(
                  (site?.siteType || siteType) === SiteType.CONSUMPTION ? 'Consumption profile' : 'Production profile',
                )}
              </Heading>
              <Box margin={{bottom: 4}}>
                <SiteChart site={site} label={translate('Load profile')} />
              </Box>
            </>
          )}
          <SiteFormContentNew siteType={site?.siteType || siteType} site={site} />
        </InnerScroll>
      </Page>
    </Form>
  )
}

export default SiteEditPage
