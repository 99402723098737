import React, {useState} from 'react'
import {IntlProvider} from 'react-intl'
import ThemeProvider from './components/ThemeProvider'
import theme from './theme'
import {ScreenSizeProvider} from './hooks/useScreenSize'
import {Provider} from 'mobx-react'
import RootStore from './store'
import useServices from './hooks/useServices'
import ThemeFavicon from './components/ThemeFavicon'
import {BrowserRouter} from 'react-router-dom'
import config from './config'
import useLocalization from 'hooks/useLocalization'
import FeatureFlagProvider from 'components/FeatureFlagProvider'
import {PaywallProvider} from 'hooks/usePaywall'

const Providers: React.FC<React.PropsWithChildren> = ({children}) => {
  const services = useServices()
  const [store] = useState(new RootStore(services))

  return (
    <ThemeProvider theme={theme}>
      <ThemeFavicon />
      <ScreenSizeProvider>
        <Provider {...store}>
          <IntlProviderWrapper>
            <FeatureFlagProvider>
              <PaywallProvider>
                <BrowserRouter>{children}</BrowserRouter>
              </PaywallProvider>
            </FeatureFlagProvider>
          </IntlProviderWrapper>
        </Provider>
      </ScreenSizeProvider>
    </ThemeProvider>
  )
}

const IntlProviderWrapper: React.FC<React.PropsWithChildren> = ({children}) => {
  const {locale} = useLocalization()
  return <IntlProvider locale={locale || config.language}>{children}</IntlProvider>
}

export default Providers
