import {PartyMemberRole} from 'domain/PartyMemberRole'
import {mockBrokerParty, mockBuyerParty1} from './mockParties'
import locations from './mockLocations'
import {CurrencyCode, PriceUnit} from 'domain/IPrice'
import IProposalDetails from 'domain/IProposalDetails'
import {IDetailedProfile} from 'domain/IDetailedProfile'
import {RepresentationBasis} from 'domain/IPartyMember'
import {IPaginatedExtendedResult} from 'domain/IPaginateResult'
import ISite, {SiteProductionTechnology, SiteState} from 'domain/ISite'
import {Country} from 'domain/ILocation'
import ITimeseriesItem, {Consumption} from 'domain/INewTimeseriesItem'
import IProduct, {ProductState} from 'domain/IProduct'
import ICertificateOffer from 'domain/ICertificateOffer'
import {INewOffer, OfferKind, OfferState} from 'domain/IOffer'
import {ContractState, IBuyerContract} from 'domain/IContract'
import ISellExcessRequest, {ExcessStatus} from 'domain/ISellExcessRequest'
import IExternalContract from 'domain/IExternalContract'
import {IFinancialPerformance} from 'domain/Portfolio'
import {IMarketListing, IMarketListingReport} from 'domain/IMarketListing'
import {ITenderRespond} from 'domain/ITenderRespond'
import {PartyRole} from 'domain/IParty'

export const detailedProfile: IDetailedProfile = {
  profile: {
    id: '1',
    login: 'test',
    firstName: 'First',
    lastName: 'Last',
    email: 'my@email.com',
    phoneNumber: '+12345678',
    phoneNumberVerified: true,
    createdBy: 'testCreate',
    buyerGuideCompletedOn: '2024-01-01',
  },
  memberships: [
    {
      id: 11,
      representationBasis: RepresentationBasis.MEMBER_OF_BOARD,
      party: {...mockBuyerParty1, location: locations[2]},
      partyMemberRoles: [PartyMemberRole.PARTY_ADMIN, PartyMemberRole.PARTY_USER],
    },
    {
      id: 22,
      representationBasis: RepresentationBasis.MEMBER_OF_BOARD,
      party: {...mockBrokerParty, location: locations[1]},
      partyMemberRoles: [PartyMemberRole.PARTY_ADMIN, PartyMemberRole.PARTY_USER],
    },
  ],
}

export const proposalDetails: IProposalDetails = {
  averagePrice: 100.0,
  totalPrice: 2400.0,
  currency: CurrencyCode.EUR,
  maxQuantity: 1000,
  greenCoverage: {
    hourlyPercent: 75.0,
    monthlyPercent: 80.0,
    yearlyPercent: 85.0,
  },
  timeseries: {
    missing: [
      {name: '0', value: 0},
      {name: '1', value: 1},
      {name: '2', value: 2},
      {name: '3', value: 3},
      {name: '4', value: 4},
      {name: '5', value: 5},
      {name: '6', value: 6},
      {name: '7', value: 7},
      {name: '8', value: 8},
      {name: '9', value: 9},
      {name: '10', value: 10},
      {name: '11', value: 11},
      {name: '12', value: 12},
      {name: '13', value: 13},
      {name: '14', value: 14},
      {name: '15', value: 15},
      {name: '16', value: 16},
      {name: '17', value: 17},
      {name: '18', value: 18},
      {name: '19', value: 19},
      {name: '20', value: 20},
      {name: '21', value: 21},
      {name: '22', value: 22},
      {name: '23', value: 23},
      {name: '24', value: 24},
    ],
    product: {
      productId: 'product-123',
      productName: 'Sample Product',
      timeseries: [
        {name: '0', value: 1},
        {name: '1', value: 2},
        {name: '2', value: 3},
        {name: '3', value: 4},
        {name: '4', value: 5},
        {name: '5', value: 6},
        {name: '6', value: 7},
        {name: '7', value: 8},
        {name: '8', value: 9},
        {name: '9', value: 10},
        {name: '10', value: 11},
        {name: '11', value: 12},
        {name: '12', value: 13},
        {name: '13', value: 14},
        {name: '14', value: 15},
        {name: '15', value: 16},
        {name: '16', value: 17},
        {name: '17', value: 18},
        {name: '18', value: 19},
        {name: '19', value: 20},
        {name: '20', value: 21},
        {name: '21', value: 22},
        {name: '22', value: 23},
        {name: '23', value: 24},
        {name: '24', value: 25},
      ],
    },
    productsTotal: [
      {name: '0', value: 24},
      {name: '1', value: 23},
      {name: '2', value: 22},
      {name: '3', value: 21},
      {name: '4', value: 20},
      {name: '5', value: 19},
      {name: '6', value: 18},
      {name: '7', value: 17},
      {name: '8', value: 16},
      {name: '9', value: 15},
      {name: '10', value: 14},
      {name: '11', value: 13},
      {name: '12', value: 12},
      {name: '13', value: 11},
      {name: '14', value: 10},
      {name: '15', value: 9},
      {name: '16', value: 8},
      {name: '17', value: 7},
      {name: '18', value: 6},
      {name: '19', value: 5},
      {name: '20', value: 4},
      {name: '21', value: 3},
      {name: '22', value: 2},
      {name: '23', value: 1},
      {name: '24', value: 0},
    ],
  },
}

export const contractedVolumes = {
  summary: {
    consumptionMwh: 373.26,
    electricityMwh: 0.0,
    certificatesQuantity: 0,
  },
  contracts: [],
  consumption: [
    {
      name: '0',
      value: 4.8,
    },
    {
      name: '1',
      value: 3.54,
    },
    {
      name: '2',
      value: 2.94,
    },
    {
      name: '3',
      value: 2.83,
    },
    {
      name: '4',
      value: 3.03,
    },
    {
      name: '5',
      value: 4.69,
    },
    {
      name: '6',
      value: 8.3,
    },
    {
      name: '7',
      value: 10.46,
    },
    {
      name: '8',
      value: 15.43,
    },
    {
      name: '9',
      value: 19.99,
    },
    {
      name: '10',
      value: 22.66,
    },
    {
      name: '11',
      value: 24.83,
    },
    {
      name: '12',
      value: 26.95,
    },
    {
      name: '13',
      value: 26.22,
    },
    {
      name: '14',
      value: 26.88,
    },
    {
      name: '15',
      value: 27.58,
    },
    {
      name: '16',
      value: 28.09,
    },
    {
      name: '17',
      value: 24.47,
    },
    {
      name: '18',
      value: 23.72,
    },
    {
      name: '19',
      value: 20.53,
    },
    {
      name: '20',
      value: 17.68,
    },
    {
      name: '21',
      value: 12.53,
    },
    {
      name: '22',
      value: 8.98,
    },
    {
      name: '23',
      value: 6.11,
    },
  ],
  contractsTimeseries: [],
  externalContractsTimeseries: [],
  externalContracts: [],
}

export const coverageOverview = contractedVolumes

export const portfolioSites: IPaginatedExtendedResult<ISite> = {
  data: [
    {
      id: 162900,
      name: 'testName',
      location: {
        id: 162850,
        addressStreet: null,
        addressCity: 'Tallinn',
        addressPostCode: null,
        addressCountry: Country.EST,
        coordinatesLat: 59.43696079999999,
        coordinatesLon: 24.7535746,
        addressSubdivision: 'Harju County',
        details: 'test',
        placeId: 'ChIJvxZW35mUkkYRcGL8GG2zAAQ',
      },
      meterId: '12312334',
    },
    {
      id: 163800,
      name: 'MyNewTestName',
      location: {
        id: 164500,
        addressStreet: null,
        addressCity: 'Tallinn',
        addressPostCode: null,
        addressCountry: Country.EST,
        coordinatesLat: 59.43696079999999,
        coordinatesLon: 24.7535746,
        addressSubdivision: 'Harju County',
        details: 'Tallin is ma home',
        placeId: 'ChIJvxZW35mUkkYRcGL8GG2zAAQ',
      },
      meterId: '5151asda',
    },
    {
      id: 166450,
      name: 'TestLeoBuilder',
      location: {
        id: 166400,
        addressStreet: null,
        addressCity: 'Tallinn',
        addressPostCode: null,
        addressCountry: Country.EST,
        coordinatesLat: 59.43696079999999,
        coordinatesLon: 24.7535746,
        addressSubdivision: 'Harju County',
        details: null,
        placeId: 'ChIJvxZW35mUkkYRcGL8GG2zAAQ',
      },
      meterId: 'asd123a',
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 3,
  totalPages: 1,
}

export const portfolioSitesDashboard = {
  summary: {
    partyTotal: [
      {
        name: '0',
        value: 36.14,
      },
      {
        name: '1',
        value: 29.75,
      },
      {
        name: '2',
        value: 27.67,
      },
      {
        name: '3',
        value: 27.76,
      },
      {
        name: '4',
        value: 35.61,
      },
      {
        name: '5',
        value: 55.7,
      },
      {
        name: '6',
        value: 75.47,
      },
      {
        name: '7',
        value: 102.26,
      },
      {
        name: '8',
        value: 137.31,
      },
      {
        name: '9',
        value: 163.44,
      },
      {
        name: '10',
        value: 183.28,
      },
      {
        name: '11',
        value: 200.62,
      },
      {
        name: '12',
        value: 202.27,
      },
      {
        name: '13',
        value: 202.06,
      },
      {
        name: '14',
        value: 207.7,
      },
      {
        name: '15',
        value: 213.18,
      },
      {
        name: '16',
        value: 198.36,
      },
      {
        name: '17',
        value: 184.56,
      },
      {
        name: '18',
        value: 163.85,
      },
      {
        name: '19',
        value: 139.88,
      },
      {
        name: '20',
        value: 108.18,
      },
      {
        name: '21',
        value: 80.94,
      },
      {
        name: '22',
        value: 59.09,
      },
      {
        name: '23',
        value: 43.89,
      },
    ],
    sitesTotal: [
      {
        name: '0',
        value: 36.14,
      },
      {
        name: '1',
        value: 29.75,
      },
      {
        name: '2',
        value: 27.67,
      },
      {
        name: '3',
        value: 27.76,
      },
      {
        name: '4',
        value: 35.61,
      },
      {
        name: '5',
        value: 55.7,
      },
      {
        name: '6',
        value: 75.47,
      },
      {
        name: '7',
        value: 102.26,
      },
      {
        name: '8',
        value: 137.31,
      },
      {
        name: '9',
        value: 163.44,
      },
      {
        name: '10',
        value: 183.28,
      },
      {
        name: '11',
        value: 200.62,
      },
      {
        name: '12',
        value: 202.27,
      },
      {
        name: '13',
        value: 202.06,
      },
      {
        name: '14',
        value: 207.7,
      },
      {
        name: '15',
        value: 213.18,
      },
      {
        name: '16',
        value: 198.36,
      },
      {
        name: '17',
        value: 184.56,
      },
      {
        name: '18',
        value: 163.85,
      },
      {
        name: '19',
        value: 139.88,
      },
      {
        name: '20',
        value: 108.18,
      },
      {
        name: '21',
        value: 80.94,
      },
      {
        name: '22',
        value: 59.09,
      },
      {
        name: '23',
        value: 43.89,
      },
    ],
  },
  sites: [
    {
      siteId: 162900,
      siteName: 'testName',
      timeseries: [
        {
          name: '0',
          value: 0.72,
        },
        {
          name: '1',
          value: 0.62,
        },
        {
          name: '2',
          value: 0.54,
        },
        {
          name: '3',
          value: 0.48,
        },
        {
          name: '4',
          value: 1.27,
        },
        {
          name: '5',
          value: 2.73,
        },
        {
          name: '6',
          value: 3.51,
        },
        {
          name: '7',
          value: 5.1,
        },
        {
          name: '8',
          value: 7.2,
        },
        {
          name: '9',
          value: 8.52,
        },
        {
          name: '10',
          value: 10.23,
        },
        {
          name: '11',
          value: 11.56,
        },
        {
          name: '12',
          value: 10.87,
        },
        {
          name: '13',
          value: 11.03,
        },
        {
          name: '14',
          value: 11.31,
        },
        {
          name: '15',
          value: 11.95,
        },
        {
          name: '16',
          value: 9.96,
        },
        {
          name: '17',
          value: 9.88,
        },
        {
          name: '18',
          value: 6.93,
        },
        {
          name: '19',
          value: 4.98,
        },
        {
          name: '20',
          value: 2.59,
        },
        {
          name: '21',
          value: 2.19,
        },
        {
          name: '22',
          value: 1.49,
        },
        {
          name: '23',
          value: 0.82,
        },
      ],
    },
    {
      siteId: 163000,
      siteName: 'testName',
      timeseries: [
        {
          name: '0',
          value: 0.72,
        },
        {
          name: '1',
          value: 0.62,
        },
        {
          name: '2',
          value: 0.54,
        },
        {
          name: '3',
          value: 0.48,
        },
        {
          name: '4',
          value: 1.27,
        },
        {
          name: '5',
          value: 2.73,
        },
        {
          name: '6',
          value: 3.51,
        },
        {
          name: '7',
          value: 5.1,
        },
        {
          name: '8',
          value: 7.2,
        },
        {
          name: '9',
          value: 8.52,
        },
        {
          name: '10',
          value: 10.23,
        },
        {
          name: '11',
          value: 11.56,
        },
        {
          name: '12',
          value: 10.87,
        },
        {
          name: '13',
          value: 11.03,
        },
        {
          name: '14',
          value: 11.31,
        },
        {
          name: '15',
          value: 11.95,
        },
        {
          name: '16',
          value: 9.96,
        },
        {
          name: '17',
          value: 9.88,
        },
        {
          name: '18',
          value: 6.93,
        },
        {
          name: '19',
          value: 4.98,
        },
        {
          name: '20',
          value: 2.59,
        },
        {
          name: '21',
          value: 2.19,
        },
        {
          name: '22',
          value: 1.49,
        },
        {
          name: '23',
          value: 0.82,
        },
      ],
    },
    {
      siteId: 105350,
      siteName: 'Site of meter ID Site SPP4',
      timeseries: [
        {
          name: '0',
          value: 3.08,
        },
        {
          name: '1',
          value: 2.24,
        },
        {
          name: '2',
          value: 1.67,
        },
        {
          name: '3',
          value: 1.95,
        },
        {
          name: '4',
          value: 3.21,
        },
        {
          name: '5',
          value: 5.97,
        },
        {
          name: '6',
          value: 7.69,
        },
        {
          name: '7',
          value: 8.81,
        },
        {
          name: '8',
          value: 12.4,
        },
        {
          name: '9',
          value: 14.36,
        },
        {
          name: '10',
          value: 15.31,
        },
        {
          name: '11',
          value: 18.45,
        },
        {
          name: '12',
          value: 19.34,
        },
        {
          name: '13',
          value: 20.35,
        },
        {
          name: '14',
          value: 22.13,
        },
        {
          name: '15',
          value: 22.6,
        },
        {
          name: '16',
          value: 20.57,
        },
        {
          name: '17',
          value: 18.62,
        },
        {
          name: '18',
          value: 20.22,
        },
        {
          name: '19',
          value: 22.43,
        },
        {
          name: '20',
          value: 20.22,
        },
        {
          name: '21',
          value: 13.18,
        },
        {
          name: '22',
          value: 6.89,
        },
        {
          name: '23',
          value: 4.18,
        },
      ],
    },
    {
      siteId: 105450,
      siteName: 'Site of meter ID Site SPP5',
      timeseries: [
        {
          name: '0',
          value: 4.54,
        },
        {
          name: '1',
          value: 2.65,
        },
        {
          name: '2',
          value: 2.98,
        },
        {
          name: '3',
          value: 4.11,
        },
        {
          name: '4',
          value: 6.25,
        },
        {
          name: '5',
          value: 11.01,
        },
        {
          name: '6',
          value: 15.67,
        },
        {
          name: '7',
          value: 21.47,
        },
        {
          name: '8',
          value: 28.79,
        },
        {
          name: '9',
          value: 33.53,
        },
        {
          name: '10',
          value: 34.68,
        },
        {
          name: '11',
          value: 34.46,
        },
        {
          name: '12',
          value: 34.82,
        },
        {
          name: '13',
          value: 33.35,
        },
        {
          name: '14',
          value: 34.46,
        },
        {
          name: '15',
          value: 35.26,
        },
        {
          name: '16',
          value: 33.08,
        },
        {
          name: '17',
          value: 30.22,
        },
        {
          name: '18',
          value: 30.07,
        },
        {
          name: '19',
          value: 28.21,
        },
        {
          name: '20',
          value: 20.82,
        },
        {
          name: '21',
          value: 13.14,
        },
        {
          name: '22',
          value: 8.84,
        },
        {
          name: '23',
          value: 6.57,
        },
      ],
    },
    {
      siteId: 105550,
      siteName: 'Site of meter ID Site SPP1',
      timeseries: [
        {
          name: '0',
          value: 5.15,
        },
        {
          name: '1',
          value: 4.52,
        },
        {
          name: '2',
          value: 4.15,
        },
        {
          name: '3',
          value: 3.45,
        },
        {
          name: '4',
          value: 5.39,
        },
        {
          name: '5',
          value: 12.07,
        },
        {
          name: '6',
          value: 19.44,
        },
        {
          name: '7',
          value: 27.63,
        },
        {
          name: '8',
          value: 39.36,
        },
        {
          name: '9',
          value: 50.44,
        },
        {
          name: '10',
          value: 60.25,
        },
        {
          name: '11',
          value: 69.15,
        },
        {
          name: '12',
          value: 69.62,
        },
        {
          name: '13',
          value: 68.09,
        },
        {
          name: '14',
          value: 70.46,
        },
        {
          name: '15',
          value: 73.48,
        },
        {
          name: '16',
          value: 69.9,
        },
        {
          name: '17',
          value: 63.61,
        },
        {
          name: '18',
          value: 53.42,
        },
        {
          name: '19',
          value: 37.75,
        },
        {
          name: '20',
          value: 24.32,
        },
        {
          name: '21',
          value: 16.42,
        },
        {
          name: '22',
          value: 12.44,
        },
        {
          name: '23',
          value: 7.23,
        },
      ],
    },
    {
      siteId: 105650,
      siteName: 'Site of meter ID Site SPP3',
      timeseries: [
        {
          name: '0',
          value: 11.04,
        },
        {
          name: '1',
          value: 9.19,
        },
        {
          name: '2',
          value: 7.82,
        },
        {
          name: '3',
          value: 7.09,
        },
        {
          name: '4',
          value: 6.24,
        },
        {
          name: '5',
          value: 5.52,
        },
        {
          name: '6',
          value: 5.93,
        },
        {
          name: '7',
          value: 8.16,
        },
        {
          name: '8',
          value: 11.56,
        },
        {
          name: '9',
          value: 13.76,
        },
        {
          name: '10',
          value: 13.58,
        },
        {
          name: '11',
          value: 13.26,
        },
        {
          name: '12',
          value: 13.57,
        },
        {
          name: '13',
          value: 13.97,
        },
        {
          name: '14',
          value: 13.71,
        },
        {
          name: '15',
          value: 13.61,
        },
        {
          name: '16',
          value: 13.73,
        },
        {
          name: '17',
          value: 13.64,
        },
        {
          name: '18',
          value: 13.75,
        },
        {
          name: '19',
          value: 14.01,
        },
        {
          name: '20',
          value: 14.36,
        },
        {
          name: '21',
          value: 14.59,
        },
        {
          name: '22',
          value: 13.84,
        },
        {
          name: '23',
          value: 12.3,
        },
      ],
    },
    {
      siteId: 105250,
      siteName: 'Site of meter ID Site SPP2',
      timeseries: [
        {
          name: '0',
          value: 1.85,
        },
        {
          name: '1',
          value: 0.96,
        },
        {
          name: '2',
          value: 1.11,
        },
        {
          name: '3',
          value: 1.39,
        },
        {
          name: '4',
          value: 2.39,
        },
        {
          name: '5',
          value: 4.63,
        },
        {
          name: '6',
          value: 7.88,
        },
        {
          name: '7',
          value: 12.56,
        },
        {
          name: '8',
          value: 15.28,
        },
        {
          name: '9',
          value: 17.45,
        },
        {
          name: '10',
          value: 20.43,
        },
        {
          name: '11',
          value: 22.3,
        },
        {
          name: '12',
          value: 23.97,
        },
        {
          name: '13',
          value: 24.88,
        },
        {
          name: '14',
          value: 24.68,
        },
        {
          name: '15',
          value: 24.05,
        },
        {
          name: '16',
          value: 22.87,
        },
        {
          name: '17',
          value: 20.51,
        },
        {
          name: '18',
          value: 17.29,
        },
        {
          name: '19',
          value: 14.21,
        },
        {
          name: '20',
          value: 12.36,
        },
        {
          name: '21',
          value: 8.7,
        },
        {
          name: '22',
          value: 4.28,
        },
        {
          name: '23',
          value: 2.82,
        },
      ],
    },
    {
      siteId: 163800,
      siteName: 'MyNewTestName',
      timeseries: [
        {
          name: '0',
          value: 0.72,
        },
        {
          name: '1',
          value: 0.62,
        },
        {
          name: '2',
          value: 0.54,
        },
        {
          name: '3',
          value: 0.48,
        },
        {
          name: '4',
          value: 1.27,
        },
        {
          name: '5',
          value: 2.73,
        },
        {
          name: '6',
          value: 3.51,
        },
        {
          name: '7',
          value: 5.1,
        },
        {
          name: '8',
          value: 7.2,
        },
        {
          name: '9',
          value: 8.52,
        },
        {
          name: '10',
          value: 10.23,
        },
        {
          name: '11',
          value: 11.56,
        },
        {
          name: '12',
          value: 10.87,
        },
        {
          name: '13',
          value: 11.03,
        },
        {
          name: '14',
          value: 11.31,
        },
        {
          name: '15',
          value: 11.95,
        },
        {
          name: '16',
          value: 9.96,
        },
        {
          name: '17',
          value: 9.88,
        },
        {
          name: '18',
          value: 6.93,
        },
        {
          name: '19',
          value: 4.98,
        },
        {
          name: '20',
          value: 2.59,
        },
        {
          name: '21',
          value: 2.19,
        },
        {
          name: '22',
          value: 1.49,
        },
        {
          name: '23',
          value: 0.82,
        },
      ],
    },
    {
      siteId: 166450,
      siteName: 'TestLeoBuilder',
      timeseries: [
        {
          name: '0',
          value: 8.33,
        },
        {
          name: '1',
          value: 8.33,
        },
        {
          name: '2',
          value: 8.33,
        },
        {
          name: '3',
          value: 8.33,
        },
        {
          name: '4',
          value: 8.33,
        },
        {
          name: '5',
          value: 8.33,
        },
        {
          name: '6',
          value: 8.33,
        },
        {
          name: '7',
          value: 8.33,
        },
        {
          name: '8',
          value: 8.33,
        },
        {
          name: '9',
          value: 8.33,
        },
        {
          name: '10',
          value: 8.33,
        },
        {
          name: '11',
          value: 8.33,
        },
        {
          name: '12',
          value: 8.33,
        },
        {
          name: '13',
          value: 8.33,
        },
        {
          name: '14',
          value: 8.33,
        },
        {
          name: '15',
          value: 8.33,
        },
        {
          name: '16',
          value: 8.33,
        },
        {
          name: '17',
          value: 8.33,
        },
        {
          name: '18',
          value: 8.33,
        },
        {
          name: '19',
          value: 8.33,
        },
        {
          name: '20',
          value: 8.33,
        },
        {
          name: '21',
          value: 8.33,
        },
        {
          name: '22',
          value: 8.33,
        },
        {
          name: '23',
          value: 8.33,
        },
      ],
    },
  ],
}

export const greenCertificates: ITimeseriesItem = {
  unit: 'MWH',
  consumption: [
    {
      name: '0',
      value: 4.8,
    },
    {
      name: '1',
      value: 3.54,
    },
    {
      name: '2',
      value: 2.94,
    },
    {
      name: '3',
      value: 2.83,
    },
    {
      name: '4',
      value: 3.03,
    },
    {
      name: '5',
      value: 4.69,
    },
    {
      name: '6',
      value: 8.3,
    },
    {
      name: '7',
      value: 10.46,
    },
    {
      name: '8',
      value: 15.43,
    },
    {
      name: '9',
      value: 19.99,
    },
    {
      name: '10',
      value: 22.66,
    },
    {
      name: '11',
      value: 24.83,
    },
    {
      name: '12',
      value: 26.95,
    },
    {
      name: '13',
      value: 26.22,
    },
    {
      name: '14',
      value: 26.88,
    },
    {
      name: '15',
      value: 27.58,
    },
    {
      name: '16',
      value: 28.09,
    },
    {
      name: '17',
      value: 24.47,
    },
    {
      name: '18',
      value: 23.72,
    },
    {
      name: '19',
      value: 20.53,
    },
    {
      name: '20',
      value: 17.68,
    },
    {
      name: '21',
      value: 12.53,
    },
    {
      name: '22',
      value: 8.98,
    },
    {
      name: '23',
      value: 6.11,
    },
  ],
  reservedCertProduction: [
    {
      name: '0',
      value: 0.0,
    },
    {
      name: '1',
      value: 0.0,
    },
    {
      name: '2',
      value: 0.0,
    },
    {
      name: '3',
      value: 0.0,
    },
    {
      name: '4',
      value: 0.0,
    },
    {
      name: '5',
      value: 0.0,
    },
    {
      name: '6',
      value: 0.0,
    },
    {
      name: '7',
      value: 0.0,
    },
    {
      name: '8',
      value: 0.0,
    },
    {
      name: '9',
      value: 0.0,
    },
    {
      name: '10',
      value: 0.0,
    },
    {
      name: '11',
      value: 0.0,
    },
    {
      name: '12',
      value: 0.0,
    },
    {
      name: '13',
      value: 0.0,
    },
    {
      name: '14',
      value: 0.0,
    },
    {
      name: '15',
      value: 0.0,
    },
    {
      name: '16',
      value: 0.0,
    },
    {
      name: '17',
      value: 0.0,
    },
    {
      name: '18',
      value: 0.0,
    },
    {
      name: '19',
      value: 0.0,
    },
    {
      name: '20',
      value: 0.0,
    },
    {
      name: '21',
      value: 0.0,
    },
    {
      name: '22',
      value: 0.0,
    },
    {
      name: '23',
      value: 0.0,
    },
  ],
  deliveredPpaProduction: [
    {
      name: '0',
      value: 0.0,
    },
    {
      name: '1',
      value: 0.0,
    },
    {
      name: '2',
      value: 1.0,
    },
    {
      name: '3',
      value: 2.0,
    },
    {
      name: '4',
      value: 3.5,
    },
    {
      name: '5',
      value: 5.0,
    },
    {
      name: '6',
      value: 7.0,
    },
    {
      name: '7',
      value: 9.0,
    },
    {
      name: '8',
      value: 11.0,
    },
    {
      name: '9',
      value: 13.0,
    },
    {
      name: '10',
      value: 15.0,
    },
    {
      name: '11',
      value: 16.5,
    },
    {
      name: '12',
      value: 17.0,
    },
    {
      name: '13',
      value: 17.0,
    },
    {
      name: '14',
      value: 16.5,
    },
    {
      name: '15',
      value: 15.0,
    },
    {
      name: '16',
      value: 13.0,
    },
    {
      name: '17',
      value: 11.0,
    },
    {
      name: '18',
      value: 9.0,
    },
    {
      name: '19',
      value: 7.0,
    },
    {
      name: '20',
      value: 5.0,
    },
    {
      name: '21',
      value: 3.5,
    },
    {
      name: '22',
      value: 2.0,
    },
    {
      name: '23',
      value: 1.0,
    },
  ],
  totalProduction: [
    {
      name: '0',
      value: 0.0,
    },
    {
      name: '1',
      value: 0.0,
    },
    {
      name: '2',
      value: 1.0,
    },
    {
      name: '3',
      value: 2.0,
    },
    {
      name: '4',
      value: 3.5,
    },
    {
      name: '5',
      value: 5.0,
    },
    {
      name: '6',
      value: 7.0,
    },
    {
      name: '7',
      value: 9.0,
    },
    {
      name: '8',
      value: 11.0,
    },
    {
      name: '9',
      value: 13.0,
    },
    {
      name: '10',
      value: 15.0,
    },
    {
      name: '11',
      value: 16.5,
    },
    {
      name: '12',
      value: 17.0,
    },
    {
      name: '13',
      value: 17.0,
    },
    {
      name: '14',
      value: 16.5,
    },
    {
      name: '15',
      value: 15.0,
    },
    {
      name: '16',
      value: 13.0,
    },
    {
      name: '17',
      value: 11.0,
    },
    {
      name: '18',
      value: 9.0,
    },
    {
      name: '19',
      value: 7.0,
    },
    {
      name: '20',
      value: 5.0,
    },
    {
      name: '21',
      value: 3.5,
    },
    {
      name: '22',
      value: 2.0,
    },
    {
      name: '23',
      value: 1.0,
    },
  ],
  production: [
    {
      name: '0',
      value: 0.0,
    },
    {
      name: '1',
      value: 0.0,
    },
    {
      name: '2',
      value: 1.0,
    },
    {
      name: '3',
      value: 2.0,
    },
    {
      name: '4',
      value: 3.5,
    },
    {
      name: '5',
      value: 5.0,
    },
    {
      name: '6',
      value: 7.0,
    },
    {
      name: '7',
      value: 9.0,
    },
    {
      name: '8',
      value: 11.0,
    },
    {
      name: '9',
      value: 13.0,
    },
    {
      name: '10',
      value: 15.0,
    },
    {
      name: '11',
      value: 16.5,
    },
    {
      name: '12',
      value: 17.0,
    },
    {
      name: '13',
      value: 17.0,
    },
    {
      name: '14',
      value: 16.5,
    },
    {
      name: '15',
      value: 15.0,
    },
    {
      name: '16',
      value: 13.0,
    },
    {
      name: '17',
      value: 11.0,
    },
    {
      name: '18',
      value: 9.0,
    },
    {
      name: '19',
      value: 7.0,
    },
    {
      name: '20',
      value: 5.0,
    },
    {
      name: '21',
      value: 3.5,
    },
    {
      name: '22',
      value: 2.0,
    },
    {
      name: '23',
      value: 1.0,
    },
  ],
  missing: [
    {
      name: '0',
      value: 4.8,
    },
    {
      name: '1',
      value: 3.54,
    },
    {
      name: '2',
      value: 1.94,
    },
    {
      name: '3',
      value: 0.83,
    },
    {
      name: '4',
      value: 0.0,
    },
    {
      name: '5',
      value: 0.0,
    },
    {
      name: '6',
      value: 1.3,
    },
    {
      name: '7',
      value: 1.46,
    },
    {
      name: '8',
      value: 4.43,
    },
    {
      name: '9',
      value: 6.99,
    },
    {
      name: '10',
      value: 7.66,
    },
    {
      name: '11',
      value: 8.33,
    },
    {
      name: '12',
      value: 9.95,
    },
    {
      name: '13',
      value: 9.22,
    },
    {
      name: '14',
      value: 10.38,
    },
    {
      name: '15',
      value: 12.58,
    },
    {
      name: '16',
      value: 15.09,
    },
    {
      name: '17',
      value: 13.47,
    },
    {
      name: '18',
      value: 14.72,
    },
    {
      name: '19',
      value: 13.53,
    },
    {
      name: '20',
      value: 12.68,
    },
    {
      name: '21',
      value: 9.03,
    },
    {
      name: '22',
      value: 6.98,
    },
    {
      name: '23',
      value: 5.11,
    },
  ],
  excess: [
    {
      name: '0',
      value: 0.0,
    },
    {
      name: '1',
      value: 0.0,
    },
    {
      name: '2',
      value: 0.0,
    },
    {
      name: '3',
      value: 0.0,
    },
    {
      name: '4',
      value: 0.0,
    },
    {
      name: '5',
      value: 0.0,
    },
    {
      name: '6',
      value: 0.0,
    },
    {
      name: '7',
      value: 0.0,
    },
    {
      name: '8',
      value: 0.0,
    },
    {
      name: '9',
      value: 0.0,
    },
    {
      name: '10',
      value: 0.0,
    },
    {
      name: '11',
      value: 0.0,
    },
    {
      name: '12',
      value: 0.0,
    },
    {
      name: '13',
      value: 0.0,
    },
    {
      name: '14',
      value: 0.0,
    },
    {
      name: '15',
      value: 0.0,
    },
    {
      name: '16',
      value: 0.0,
    },
    {
      name: '17',
      value: 0.0,
    },
    {
      name: '18',
      value: 0.0,
    },
    {
      name: '19',
      value: 0.0,
    },
    {
      name: '20',
      value: 0.0,
    },
    {
      name: '21',
      value: 0.0,
    },
    {
      name: '22',
      value: 0.0,
    },
    {
      name: '23',
      value: 0.0,
    },
  ],
  summary: {
    excess: [],
    missing: {
      certificateQuantity: 174,
    },
  },
}

export const buyerMarketplaceProducts: IPaginatedExtendedResult<IProduct> = {
  data: [
    {
      id: 169800,
      name: 'Portugal Wind RNBL',
      state: ProductState.IN_OPERATION,
      description: 'Large wing Lisbon',
      totalVolumeForSaleMwh: 6723.0,
      salesStart: null,
      salesEnd: null,
      emissionFactor: null,
      price: {
        value: 1.12,
        unit: PriceUnit.EUR_MWH,
      },
      site: {
        id: 169900,
        name: 'Lisbon Wind',
        state: SiteState.IN_OPERATION,
        location: {
          id: 169700,
          addressStreet: null,
          addressCity: 'Cascais',
          addressPostCode: '2750',
          addressCountry: Country.PRT,
          coordinatesLat: 38.6960614,
          coordinatesLon: -9.4303646,
          addressSubdivision: 'Lisbon',
          details: null,
          placeId: 'ChIJl5q3eXPEHg0RwrsHGH47DYc',
        },
        productionTechnology: SiteProductionTechnology.WIND,
        timeseriesId: 'e510c678-4715-4241-b855-f29a4d48811e',
      },
      image: null,
      availableQuantity: 6723,
      reservedQuantity: 0,
      soldQuantity: 0,
      totalQuantity: 6723,
      maxQuantity: 174,
    },
    {
      id: 169650,
      name: 'Supper Wind',
      state: ProductState.IN_OPERATION,
      description: 'Awesome Wind',
      totalVolumeForSaleMwh: 200.0,
      salesStart: null,
      salesEnd: null,
      emissionFactor: null,
      price: {
        value: 7.3,
        unit: PriceUnit.GBP_MWH,
      },
      site: {
        id: 169600,
        name: 'Awesome Wind 2',
        state: SiteState.IN_OPERATION,
        location: {
          id: 169550,
          addressStreet: null,
          addressCity: 'Edinburgh',
          addressPostCode: null,
          addressCountry: Country.GBR,
          coordinatesLat: 55.953252,
          coordinatesLon: -3.188267,
          addressSubdivision: 'Scotland',
          details: null,
          placeId: 'ChIJIyaYpQC4h0gRJxfnfHsU8mQ',
        },
        productionTechnology: SiteProductionTechnology.WIND,
        timeseriesId: '0bba927a-d9ef-4ef6-8d57-1cfa72bfaf4f',
      },
      image: null,
      availableQuantity: 200,
      reservedQuantity: 0,
      soldQuantity: 0,
      totalQuantity: 200,
      maxQuantity: 137,
    },
    {
      id: 168650,
      name: 'Supper Buda',
      state: ProductState.IN_OPERATION,
      description: 'amazing stuff',
      totalVolumeForSaleMwh: 216.0,
      salesStart: null,
      salesEnd: null,
      emissionFactor: null,
      price: {
        value: 8.0,
        unit: PriceUnit.GBP_MWH,
      },
      site: {
        id: 168750,
        name: 'Super Buda',
        state: SiteState.IN_OPERATION,
        location: {
          id: 168550,
          addressStreet: 'Gresham Street',
          addressCity: null,
          addressPostCode: 'EC2V 7PG',
          addressCountry: Country.GBR,
          coordinatesLat: 51.5150538,
          coordinatesLon: -0.0929148,
          addressSubdivision: 'England',
          details: null,
          placeId: 'ChIJ1TUHs6ocdkgRr04eoFJbH40',
        },
        productionTechnology: SiteProductionTechnology.SOLAR,
        timeseriesId: 'd9a60e10-04d7-4930-a9ec-b6edb0760f52',
      },
      image: null,
      availableQuantity: 216,
      reservedQuantity: 0,
      soldQuantity: 0,
      totalQuantity: 216,
      maxQuantity: 143,
    },
  ],
  page: 1,
  pageSize: 3,
  totalElements: 3,
  totalPages: 1,
}

export const certificateOffer: ICertificateOffer = {
  id: 112850,
  name: 'Purchase designer offer #16',
  state: OfferState.ACCEPTED,
  purchaseInterval: {
    year: 2024,
    month: 'February',
  },
  estimatedVolume: 134,
  contract: {
    id: 112650,
    name: 'Purchase designer contract #16',
    state: ContractState.SIGNED,
  },
  averagePrice: 11.2,
  totalPrice: 1500.8,
  currency: CurrencyCode.EUR,
  product: {
    id: '169650',
    name: 'Windy Knoll Wind Farm',
    technology: SiteProductionTechnology.WIND,
    quantity: 134,
    certificatePrice: '11.20',
    image: null,
  },
  greenCoverage: {
    hourlyPercent: 37.69,
    monthlyPercent: 37.69,
    yearlyPercent: 3.14,
  },
  matchedCerts: [
    {
      cert: {
        end: 'G02635NWSC1725348587010624300624GEN',
        start: 'G02635NWSC1725348587010624300624GEN',
      },
      timestamp: '2024-06-01T00:00:00Z',
    },
    {
      cert: {
        end: 'G02635NWSC1725348592010624300624GEN',
        start: 'G02635NWSC1725348591010624300624GEN',
      },
      timestamp: '2024-06-01T01:00:00Z',
    },
    {
      cert: {
        end: 'G02635NWSC1725348597010624300624GEN',
        start: 'G02635NWSC1725348596010624300624GEN',
      },
      timestamp: '2024-06-01T02:00:00Z',
    },
  ],
  timeseries: {
    missing: [
      {
        name: '0',
        value: 0.0,
      },
      {
        name: '1',
        value: 1.0,
      },
      {
        name: '2',
        value: 2.0,
      },
      {
        name: '3',
        value: 3.0,
      },
      {
        name: '4',
        value: 4.0,
      },
      {
        name: '5',
        value: 5.0,
      },
      {
        name: '6',
        value: 6.0,
      },
      {
        name: '7',
        value: 7.0,
      },
      {
        name: '8',
        value: 8.0,
      },
      {
        name: '9',
        value: 9.0,
      },
      {
        name: '10',
        value: 10.0,
      },
      {
        name: '11',
        value: 11.0,
      },
      {
        name: '12',
        value: 12.0,
      },
      {
        name: '13',
        value: 13.0,
      },
      {
        name: '14',
        value: 14.0,
      },
      {
        name: '15',
        value: 15.0,
      },
      {
        name: '16',
        value: 16.0,
      },
      {
        name: '17',
        value: 17.0,
      },
      {
        name: '18',
        value: 18.0,
      },
      {
        name: '19',
        value: 19.0,
      },
      {
        name: '20',
        value: 20.0,
      },
      {
        name: '21',
        value: 21.0,
      },
      {
        name: '22',
        value: 22.0,
      },
      {
        name: '23',
        value: 23.0,
      },
    ],
    product: {
      productId: 49700,
      productName: 'Windy Knoll Wind Farm',
      timeseries: [
        {
          name: '0',
          value: 0.0,
        },
        {
          name: '1',
          value: 1.0,
        },
        {
          name: '2',
          value: 2.0,
        },
        {
          name: '3',
          value: 3.0,
        },
        {
          name: '4',
          value: 4.0,
        },
        {
          name: '5',
          value: 5.0,
        },
        {
          name: '6',
          value: 6.0,
        },
        {
          name: '7',
          value: 7.0,
        },
        {
          name: '8',
          value: 8.0,
        },
        {
          name: '9',
          value: 9.0,
        },
        {
          name: '10',
          value: 10.0,
        },
        {
          name: '11',
          value: 11.0,
        },
        {
          name: '12',
          value: 12.0,
        },
        {
          name: '13',
          value: 13.0,
        },
        {
          name: '14',
          value: 14.0,
        },
        {
          name: '15',
          value: 15.0,
        },
        {
          name: '16',
          value: 16.0,
        },
        {
          name: '17',
          value: 17.0,
        },
        {
          name: '18',
          value: 18.0,
        },
        {
          name: '19',
          value: 19.0,
        },
        {
          name: '20',
          value: 20.0,
        },
        {
          name: '21',
          value: 21.0,
        },
        {
          name: '22',
          value: 22.0,
        },
        {
          name: '23',
          value: 23.0,
        },
      ],
    },
  },
}

export const adminSellRequests: IPaginatedExtendedResult<ISellExcessRequest> = {
  data: [
    {
      id: 6,
      party: {
        id: 9701,
        name: 'AWS UK',
      },
      technologyKind: SiteProductionTechnology.HYDRO,
      quantity: 3,
      status: ExcessStatus.PENDING,
      certPrice: 5.0,
    },
    {
      id: 7,
      party: {
        id: 9701,
        name: 'AWS UK',
      },
      technologyKind: SiteProductionTechnology.SOLAR,
      quantity: 5,
      status: ExcessStatus.PENDING,
      certPrice: 6.0,
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 2,
  totalPages: 1,
}

export const adminWarehouse = {
  unit: 'MWH',
  mixed: [
    {
      name: '0',
      value: 377023.75,
    },
    {
      name: '1',
      value: 771033.54,
    },
    {
      name: '2',
      value: 771007.6,
    },
    {
      name: '3',
      value: 771063.37,
    },
    {
      name: '4',
      value: 817024.6,
    },
    {
      name: '5',
      value: 862977.12,
    },
    {
      name: '6',
      value: 1000792.14,
    },
    {
      name: '7',
      value: 1070117.97,
    },
    {
      name: '8',
      value: 1116087.66,
    },
    {
      name: '9',
      value: 1415790.1,
    },
    {
      name: '10',
      value: 1644654.53,
    },
    {
      name: '11',
      value: 1828359.91,
    },
    {
      name: '12',
      value: 2292208.19,
    },
    {
      name: '13',
      value: 2281140.49,
    },
    {
      name: '14',
      value: 1826728.06,
    },
    {
      name: '15',
      value: 1643016.16,
    },
    {
      name: '16',
      value: 1412597.96,
    },
    {
      name: '17',
      value: 1115301.31,
    },
    {
      name: '18',
      value: 1069336.95,
    },
    {
      name: '19',
      value: 1000801.22,
    },
    {
      name: '20',
      value: 862926.2,
    },
    {
      name: '21',
      value: 816957.46,
    },
    {
      name: '22',
      value: 771052.66,
    },
    {
      name: '23',
      value: 771060.53,
    },
  ],
  hydro: [
    {
      name: '0',
      value: 186718.2,
    },
    {
      name: '1',
      value: 391141.67,
    },
    {
      name: '2',
      value: 391167.67,
    },
    {
      name: '3',
      value: 391193.67,
    },
    {
      name: '4',
      value: 391232.67,
    },
    {
      name: '5',
      value: 391271.67,
    },
    {
      name: '6',
      value: 391323.67,
    },
    {
      name: '7',
      value: 391375.67,
    },
    {
      name: '8',
      value: 391427.67,
    },
    {
      name: '9',
      value: 391479.67,
    },
    {
      name: '10',
      value: 391531.67,
    },
    {
      name: '11',
      value: 391570.67,
    },
    {
      name: '12',
      value: 391583.67,
    },
    {
      name: '13',
      value: 391583.67,
    },
    {
      name: '14',
      value: 391570.67,
    },
    {
      name: '15',
      value: 391531.67,
    },
    {
      name: '16',
      value: 391479.67,
    },
    {
      name: '17',
      value: 391427.67,
    },
    {
      name: '18',
      value: 391375.67,
    },
    {
      name: '19',
      value: 391323.67,
    },
    {
      name: '20',
      value: 391271.67,
    },
    {
      name: '21',
      value: 391232.67,
    },
    {
      name: '22',
      value: 391193.67,
    },
    {
      name: '23',
      value: 391167.67,
    },
  ],
  solar: [
    {
      name: '0',
      value: 116.76,
    },
    {
      name: '1',
      value: 9.0,
    },
    {
      name: '2',
      value: 22.0,
    },
    {
      name: '3',
      value: 35.0,
    },
    {
      name: '4',
      value: 45966.66,
    },
    {
      name: '5',
      value: 91898.32,
    },
    {
      name: '6',
      value: 229660.81,
    },
    {
      name: '7',
      value: 298950.32,
    },
    {
      name: '8',
      value: 344888.49,
    },
    {
      name: '9',
      value: 644529.35,
    },
    {
      name: '10',
      value: 873325.62,
    },
    {
      name: '11',
      value: 1056993.77,
    },
    {
      name: '12',
      value: 1520865.14,
    },
    {
      name: '13',
      value: 1509797.57,
    },
    {
      name: '14',
      value: 1055412.69,
    },
    {
      name: '15',
      value: 871744.54,
    },
    {
      name: '16',
      value: 641367.19,
    },
    {
      name: '17',
      value: 344097.95,
    },
    {
      name: '18',
      value: 298159.78,
    },
    {
      name: '19',
      value: 229660.81,
    },
    {
      name: '20',
      value: 91898.32,
    },
    {
      name: '21',
      value: 45966.66,
    },
    {
      name: '22',
      value: 35.0,
    },
    {
      name: '23',
      value: 22.0,
    },
  ],
  wind: [
    {
      name: '0',
      value: 190188.79,
    },
    {
      name: '1',
      value: 379882.87,
    },
    {
      name: '2',
      value: 379817.94,
    },
    {
      name: '3',
      value: 379834.71,
    },
    {
      name: '4',
      value: 379825.27,
    },
    {
      name: '5',
      value: 379807.12,
    },
    {
      name: '6',
      value: 379807.66,
    },
    {
      name: '7',
      value: 379791.97,
    },
    {
      name: '8',
      value: 379771.51,
    },
    {
      name: '9',
      value: 379781.08,
    },
    {
      name: '10',
      value: 379797.24,
    },
    {
      name: '11',
      value: 379795.47,
    },
    {
      name: '12',
      value: 379759.38,
    },
    {
      name: '13',
      value: 379759.26,
    },
    {
      name: '14',
      value: 379744.71,
    },
    {
      name: '15',
      value: 379739.95,
    },
    {
      name: '16',
      value: 379751.1,
    },
    {
      name: '17',
      value: 379775.69,
    },
    {
      name: '18',
      value: 379801.5,
    },
    {
      name: '19',
      value: 379816.75,
    },
    {
      name: '20',
      value: 379756.21,
    },
    {
      name: '21',
      value: 379758.13,
    },
    {
      name: '22',
      value: 379824.0,
    },
    {
      name: '23',
      value: 379870.86,
    },
  ],
  missing: [
    {
      name: '0',
      value: 113855.1,
    },
    {
      name: '1',
      value: 115041.0,
    },
    {
      name: '2',
      value: 112098.6,
    },
    {
      name: '3',
      value: 110885.3,
    },
    {
      name: '4',
      value: 109571.0,
    },
    {
      name: '5',
      value: 111333.0,
    },
    {
      name: '6',
      value: 110927.1,
    },
    {
      name: '7',
      value: 107884.3,
    },
    {
      name: '8',
      value: 109684.4,
    },
    {
      name: '9',
      value: 113480.7,
    },
    {
      name: '10',
      value: 114186.2,
    },
    {
      name: '11',
      value: 116673.2,
    },
    {
      name: '12',
      value: 118762.8,
    },
    {
      name: '13',
      value: 120772.6,
    },
    {
      name: '14',
      value: 122124.5,
    },
    {
      name: '15',
      value: 121221.8,
    },
    {
      name: '16',
      value: 121404.4,
    },
    {
      name: '17',
      value: 121067.1,
    },
    {
      name: '18',
      value: 124399.1,
    },
    {
      name: '19',
      value: 123236.5,
    },
    {
      name: '20',
      value: 121532.5,
    },
    {
      name: '21',
      value: 119393.9,
    },
    {
      name: '22',
      value: 118667.4,
    },
    {
      name: '23',
      value: 115099.9,
    },
  ],
  certificateStock: {
    mixed: 28309059,
    hydro: 9188177,
    solar: 10195424,
    wind: 8925459,
  },
}

export const consumption: Consumption[] = [
  {name: '0', value: 0},
  {name: '1', value: 1},
  {name: '2', value: 2},
  {name: '3', value: 3},
  {name: '4', value: 4},
  {name: '5', value: 5},
  {name: '6', value: 6},
  {name: '7', value: 7},
  {name: '8', value: 8},
  {name: '9', value: 9},
  {name: '10', value: 10},
  {name: '11', value: 11},
  {name: '12', value: 12},
  {name: '13', value: 13},
  {name: '14', value: 14},
  {name: '15', value: 15},
  {name: '16', value: 16},
  {name: '17', value: 17},
  {name: '18', value: 18},
  {name: '19', value: 19},
  {name: '20', value: 20},
  {name: '21', value: 21},
  {name: '22', value: 22},
  {name: '23', value: 23},
  {name: '24', value: 24},
]

export const externalContracts: IPaginatedExtendedResult<IExternalContract> = {
  data: [
    {
      id: 6,
      name: 'BSWG160 - Bunschotenweg DC1 + 2 - Rotterdam',
      partyId: 104951,
      volumePercent: '100.00',
      pricePerMwh: '25.00',
      startDate: '2024-03-01',
      endDate: '2034-03-01',
    },
    {
      id: 12,
      name: 'Test',
      partyId: 104951,
      volumePercent: '100.00',
      pricePerMwh: '5.00',
      startDate: '2024-05-22',
      endDate: '2024-05-31',
    },
    {
      id: 13,
      name: 'test',
      partyId: 104951,
      volumePercent: '100.00',
      pricePerMwh: '5.00',
      startDate: '2024-06-05',
      endDate: '2024-06-07',
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 3,
  totalPages: 1,
}

export const financialPerformance: IFinancialPerformance = {
  summary: {
    generationMwh: 193.33,
    profitCurrency: CurrencyCode.EUR,
    totalProfit: 83379.46,
    averageProfit: 27793.15,
  },
  contracts: [
    {
      id: 6,
      name: 'BSWG160 - Bunschotenweg DC1 + 2 - Rotterdam',
      price: 25.0,
      volumeMwh: 193.33333333333331,
      totalPrice: 4833.33,
      averageMarketPrice: 68.87,
      totalProfit: 83379.46,
      averageProfit: 431.27,
    },
    {
      id: 12,
      name: 'Test',
      price: 5.0,
      volumeMwh: 0.0,
      totalPrice: 0.0,
      averageMarketPrice: 68.87,
      totalProfit: 0.0,
      averageProfit: 0.0,
    },
    {
      id: 13,
      name: 'test',
      price: 5.0,
      volumeMwh: 0.0,
      totalPrice: 0.0,
      averageMarketPrice: 68.87,
      totalProfit: 0.0,
      averageProfit: 0.0,
    },
  ],
  contractsTimeseries: [
    {
      contractId: 6,
      timeseries: {
        currency: CurrencyCode.EUR,
        series: [
          {
            name: '0',
            value: 0.0,
          },
          {
            name: '1',
            value: 0.0,
          },
          {
            name: '2',
            value: 3298.67,
          },
          {
            name: '3',
            value: 3317.33,
          },
          {
            name: '4',
            value: 3952.4700000000003,
          },
          {
            name: '5',
            value: 3913.94,
          },
          {
            name: '6',
            value: 4453.4400000000005,
          },
          {
            name: '7',
            value: 4233.28,
          },
          {
            name: '8',
            value: 3726.57,
          },
          {
            name: '9',
            value: 2998.62,
          },
          {
            name: '10',
            value: 3195.09,
          },
          {
            name: '11',
            value: 3063.11,
          },
          {
            name: '12',
            value: 2063.07,
          },
          {
            name: '13',
            value: 1583.73,
          },
          {
            name: '14',
            value: 2605.4900000000002,
          },
          {
            name: '15',
            value: 3181.26,
          },
          {
            name: '16',
            value: 4069.98,
          },
          {
            name: '17',
            value: 4775.47,
          },
          {
            name: '18',
            value: 5734.1,
          },
          {
            name: '19',
            value: 5747.96,
          },
          {
            name: '20',
            value: 5161.75,
          },
          {
            name: '21',
            value: 4771.91,
          },
          {
            name: '22',
            value: 4009.78,
          },
          {
            name: '23',
            value: 3522.4300000000003,
          },
        ],
      },
    },
    {
      contractId: 12,
      timeseries: {
        currency: CurrencyCode.EUR,
        series: [
          {
            name: '0',
            value: 0.0,
          },
          {
            name: '1',
            value: 0.0,
          },
          {
            name: '2',
            value: 0.0,
          },
          {
            name: '3',
            value: 0.0,
          },
          {
            name: '4',
            value: 0.0,
          },
          {
            name: '5',
            value: 0.0,
          },
          {
            name: '6',
            value: 0.0,
          },
          {
            name: '7',
            value: 0.0,
          },
          {
            name: '8',
            value: 0.0,
          },
          {
            name: '9',
            value: 0.0,
          },
          {
            name: '10',
            value: 0.0,
          },
          {
            name: '11',
            value: 0.0,
          },
          {
            name: '12',
            value: 0.0,
          },
          {
            name: '13',
            value: 0.0,
          },
          {
            name: '14',
            value: 0.0,
          },
          {
            name: '15',
            value: 0.0,
          },
          {
            name: '16',
            value: 0.0,
          },
          {
            name: '17',
            value: 0.0,
          },
          {
            name: '18',
            value: 0.0,
          },
          {
            name: '19',
            value: 0.0,
          },
          {
            name: '20',
            value: 0.0,
          },
          {
            name: '21',
            value: 0.0,
          },
          {
            name: '22',
            value: 0.0,
          },
          {
            name: '23',
            value: 0.0,
          },
        ],
      },
    },
    {
      contractId: 13,
      timeseries: {
        currency: CurrencyCode.EUR,
        series: [
          {
            name: '0',
            value: 0.0,
          },
          {
            name: '1',
            value: 0.0,
          },
          {
            name: '2',
            value: 0.0,
          },
          {
            name: '3',
            value: 0.0,
          },
          {
            name: '4',
            value: 0.0,
          },
          {
            name: '5',
            value: 0.0,
          },
          {
            name: '6',
            value: 0.0,
          },
          {
            name: '7',
            value: 0.0,
          },
          {
            name: '8',
            value: 0.0,
          },
          {
            name: '9',
            value: 0.0,
          },
          {
            name: '10',
            value: 0.0,
          },
          {
            name: '11',
            value: 0.0,
          },
          {
            name: '12',
            value: 0.0,
          },
          {
            name: '13',
            value: 0.0,
          },
          {
            name: '14',
            value: 0.0,
          },
          {
            name: '15',
            value: 0.0,
          },
          {
            name: '16',
            value: 0.0,
          },
          {
            name: '17',
            value: 0.0,
          },
          {
            name: '18',
            value: 0.0,
          },
          {
            name: '19',
            value: 0.0,
          },
          {
            name: '20',
            value: 0.0,
          },
          {
            name: '21',
            value: 0.0,
          },
          {
            name: '22',
            value: 0.0,
          },
          {
            name: '23',
            value: 0.0,
          },
        ],
      },
    },
  ],
}

export const marketListings: IPaginatedExtendedResult<IMarketListing> = {
  data: [
    {
      id: 962,
      productId: 140050,
      productName: 'LeoTestProductName12Q',
      currency: CurrencyCode.EUR,
      certificateId: 1059,
      startDate: '2024-04-01',
      certPrice: 23.0,
      totalQuantity: 300,
      soldQuantity: 0,
      reservedQuantity: 0,
      availableQuantity: 300,
    },
    {
      id: 961,
      productId: 140050,
      productName: 'LeoTestProductName12Q',
      currency: CurrencyCode.EUR,
      certificateId: 1058,
      startDate: '2024-03-01',
      certPrice: 21.0,
      totalQuantity: 199,
      soldQuantity: 0,
      reservedQuantity: 0,
      availableQuantity: 199,
    },
    {
      id: 956,
      productId: 140050,
      productName: 'LeoTestProductName12Q',
      currency: CurrencyCode.EUR,
      certificateId: 1052,
      startDate: '2024-02-01',
      certPrice: 15.0,
      totalQuantity: 136,
      soldQuantity: 0,
      reservedQuantity: 0,
      availableQuantity: 136,
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 3,
  totalPages: 1,
}

export const offers: IPaginatedExtendedResult<INewOffer> = {
  data: [
    {
      id: 110550,
      name: 'Purchase designer offer #19',
      startDate: '2024-02-01T00:00:00',
      endDate: '2024-02-29T23:59:59',
      certPrice: 10.0,
      priceUnit: PriceUnit.GBP_MWH,
      totalPrice: 2740.0,
      purchaseInterval: {
        year: 2024,
        month: 'February',
      },
      state: OfferState.PURCHASE_CONFIG_IN_RETAILER_REVIEW,
      kind: OfferKind.CERTIFICATE_TRADE,
      validFrom: '2024-03-22T10:11:55.827785',
      validTo: '2024-04-06T10:11:55.827785',
      contract: {
        id: 110350,
        name: 'Purchase designer contract #19',
        state: ContractState.OFFER,
        signingDate: null,
        validFrom: '2024-04-01T00:00:00',
        validTo: '2024-04-30T23:59:59',
        contractType: {
          id: 1300,
          name: 'Energy Supply Agreement',
        },
        volumeMwh: 274.0,
      },
      customer: {
        id: 104951,
        name: 'Iron Mountain',
        partyType: null,
      },
    },
    {
      id: 106700,
      name: 'Purchase designer offer #4',
      startDate: '2024-02-01T00:00:00',
      endDate: '2024-02-29T23:59:59',
      certPrice: 7.1,
      priceUnit: PriceUnit.GBP_MWH,
      totalPrice: 596.4,
      purchaseInterval: {
        year: 2024,
        month: 'February',
      },
      state: OfferState.PURCHASE_CONFIG_IN_RETAILER_REVIEW,
      kind: OfferKind.CERTIFICATE_TRADE,
      validFrom: '2024-03-22T04:17:04.487595',
      validTo: '2024-04-06T04:17:04.487595',
      contract: {
        id: 106500,
        name: 'Purchase designer contract #4',
        state: ContractState.OFFER,
        signingDate: null,
        validFrom: '2024-04-01T00:00:00',
        validTo: '2024-04-30T23:59:59',
        contractType: {
          id: 1300,
          name: 'Energy Supply Agreement',
        },
        volumeMwh: 84.0,
      },
      customer: {
        id: 104951,
        name: 'Iron Mountain',
        partyType: null,
      },
    },
    {
      id: 84700,
      name: 'Purchase designer offer #15',
      startDate: '2024-01-01T00:00:00',
      endDate: '2024-01-31T23:59:59',
      certPrice: 10.3,
      priceUnit: PriceUnit.GBP_MWH,
      totalPrice: 1545.0,
      purchaseInterval: {
        year: 2024,
        month: 'January',
      },
      state: OfferState.PURCHASE_CONFIG_IN_RETAILER_REVIEW,
      kind: OfferKind.CERTIFICATE_TRADE,
      validFrom: '2024-02-06T08:40:21.195094',
      validTo: '2024-02-21T08:40:21.195094',
      contract: {
        id: 84500,
        name: 'Purchase designer contract #15',
        state: ContractState.OFFER,
        signingDate: null,
        validFrom: '2024-03-01T00:00:00',
        validTo: '2024-03-31T23:59:59',
        contractType: {
          id: 1300,
          name: 'Energy Supply Agreement',
        },
        volumeMwh: 150.0,
      },
      customer: {
        id: 73001,
        name: 'T Charger',
        partyType: null,
      },
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 3,
  totalPages: 1,
}

export const marketListingCertReport: IMarketListingReport = {
  hourly: [
    {
      contracts: [
        {
          certs: {
            end: 'G02635NWSC1723801283010624300624GEN',
            start: 'G02635NWSC1723801284010624300624GEN',
          },
          id: 7200,
        },
        {
          certs: {
            end: 'G02635NWSC1723801283010624300624GEN',
            start: 'G02635NWSC1723801284010624300624GEN',
          },
          id: 7450,
        },
      ],
      gap: {
        end: 'G02635NWSC1723801283010624300624GEN',
        start: 'G02635NWSC1723801282010624300624GEN',
      },
      timestamp: '2024-06-01T00:00:00Z',
      total: {
        end: 'G02635NWSC1723801285010624300624GEN',
        start: 'G02635NWSC1723801281010624300624GEN',
      },
    },
    {
      contracts: [
        {
          certs: {
            end: 'G02635NWSC1723801285010624300624GEN',
            start: 'G02635NWSC1723801286010624300624GEN',
          },
          id: 7200,
        },
        {
          certs: {
            end: 'G02635NWSC1723801285010624300624GEN',
            start: 'G02635NWSC1723801286010624300624GEN',
          },
          id: 7450,
        },
      ],
      gap: null,
      timestamp: '2024-06-01T01:00:00Z',
      total: {
        end: 'G02635NWSC1723801287010624300624GEN',
        start: 'G02635NWSC1723801283010624300624GEN',
      },
    },
    {
      contracts: [
        {
          certs: null,
          id: 7200,
        },
        {
          certs: {
            end: 'G02635NWSC1723801287010624300624GEN',
            start: 'G02635NWSC1723801288010624300624GEN',
          },
          id: 7450,
        },
      ],
      gap: {
        end: 'G02635NWSC1723801287010624300624GEN',
        start: 'G02635NWSC1723801286010624300624GEN',
      },
      timestamp: '2024-06-01T02:00:00Z',
      total: {
        end: 'G02635NWSC1723801289010624300624GEN',
        start: 'G02635NWSC1723801285010624300624GEN',
      },
    },
  ],
}

export const tenderResponses: IPaginatedExtendedResult<ITenderRespond> = {
  data: [
    {
      id: 1,
      price: '10.00',
      siteName: 'Test',
      technology: SiteProductionTechnology.HYDRO,
      state: SiteState.IN_CONSTRUCTION,
      capacityMwac: 6002.0,
      operationsStart: '2026-06-29',
      attachmentId: '1',
      year: 2025,
    },
    {
      id: 2,
      price: '100.00',
      siteName: 'test2',
      technology: SiteProductionTechnology.WIND,
      state: SiteState.IN_CONSTRUCTION,
      capacityMwac: 1000.0,
      operationsStart: '2012-12-12',
      attachmentId: '2',
      year: 2025,
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 2,
  totalPages: 1,
}

export const buyerContracts: IPaginatedExtendedResult<IBuyerContract> = {
  data: [
    {
      id: 1,
      name: 'Contract 1',
      state: ContractState.ACTIVE,
      startDate: '2024-01-01',
      endDate: '2024-01-31',
      certPrice: 5,
      priceUnit: PriceUnit.GBP_MWH,
      totalPrice: 500,
      purchaseInterval: {
        year: 0,
        month: '',
      },
      kind: '',
      validFrom: '2024-01-01',
      validTo: '2024-01-31',
      seller: undefined,
      contract: {
        id: 1,
        name: 'Sample Contract',
        state: ContractState.ACTIVE,
        signingDate: '2024-01-01',
        validFrom: '2024-01-01',
        validTo: '2024-01-31',
        contractItems: [],
        offers: [],
        parties: [],
        volumeMwh: 1000,
        contractParties: [],
        offerId: 1,
        contractType: undefined,
        summary: undefined,
        volumeProfile: undefined,
      },
    },
    {
      id: 2,
      name: 'Contract 2',
      state: ContractState.ACTIVE,
      startDate: '2024-02-01',
      endDate: '2024-02-28',
      certPrice: 200,
      priceUnit: PriceUnit.GBP_MWH,
      totalPrice: 10000,
      purchaseInterval: {
        year: 0,
        month: '',
      },
      kind: '',
      validFrom: '2024-02-01',
      validTo: '2024-02-28',
      seller: undefined,
      contract: {
        id: 2,
        name: 'Sample Contract 2',
        state: ContractState.ACTIVE,
        signingDate: '2024-02-01',
        validFrom: '2024-02-01',
        validTo: '2024-02-28',
        contractItems: [],
        offers: [],
        parties: [],
        volumeMwh: 5,
        contractParties: [],
        offerId: 2,
        contractType: undefined,
        summary: undefined,
        volumeProfile: undefined,
      },
    },
  ],
  page: 1,
  pageSize: 25,
  totalElements: 2,
  totalPages: 1,
}
