import {isNil} from 'lodash-es'
import PurchaseGroup from './IPurchaseGroup'

export interface IContractSubject {
  id: number
  type: ContractSubjectType
  // if type = GENERAL_PROFILE then name will be translate(General profile), else if a purchase group - the name of the purchase group
  name: string
}

export enum ContractSubjectType {
  GENERAL_PROFILE = 'GENERAL_PROFILE',
  PURCHASE_GROUP = 'PURCHASE_GROUP',
}

export const purchaseGroupToContractSubject = (purchaseGroup: PurchaseGroup): IContractSubject => {
  if (isNil(purchaseGroup)) {
    return null
  }

  return {id: purchaseGroup.id, type: ContractSubjectType.PURCHASE_GROUP, name: purchaseGroup.name} as IContractSubject
}

export const generalProfileContractSubject = (partyId: number): IContractSubject => {
  const generalProfileSubject = {
    id: partyId,
    type: ContractSubjectType.GENERAL_PROFILE,
    name: 'General Profile',
  } as IContractSubject

  return generalProfileSubject
}
