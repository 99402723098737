import {FormControlLabel, RadioGroup, Skeleton} from '@mui/material'
import {PaddleEventData} from '@paddle/paddle-js'
import {rootServiceAtom} from 'atoms/general'
import {paddleAtom} from 'atoms/paddle'
import {partyAtom} from 'atoms/party'
import Box from 'components/Box'
import Page from 'components/Page'
import {PricingTiers, Tier} from 'components/PaywallDialog'
import Radio from 'components/Radio'
import Text from 'components/Text'
import config from 'config'
import {ISubscriptionPlan} from 'domain/IParty'
import {EventSourcePolyfill} from 'event-source-polyfill'
import useProfile from 'hooks/useProfile'
import useStore from 'hooks/useStore'
import useTheme from 'hooks/useTheme'
import {useAtom, useAtomValue} from 'jotai'
import React, {useEffect, useState} from 'react'
import useLocalization from '../../../hooks/useLocalization'

const EventSource = EventSourcePolyfill

enum ServerSentEvents {
  SUBSCRIPTION_PLAN_UPGRADED = 'SUBSCRIPTION_PLAN_UPGRADED',
}

enum products {
  PRO = 0,
  ENTERPRISE = 1,
}

function getPlanName(plan: ISubscriptionPlan) {
  switch (plan) {
    case ISubscriptionPlan.PRO:
      return 'Pro'
    case ISubscriptionPlan.ENTERPRISE:
      return 'Enterprise'
    default:
      return 'Basic'
  }
}

const Subscription = () => {
  const {translate} = useLocalization()
  const [proPrice, setProPrice] = useState('')
  const [enterprisePrice, setEnterprisePrice] = useState('')
  const [billingCycle, setBillingCycle] = useState('month')
  const [party, setParty] = useAtom(partyAtom)
  const {user} = useProfile()
  const theme = useTheme()
  const {alertStore} = useStore()
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [authToken, setAuthToken] = useState(null)
  const [eventSource, setEventSource] = useState<EventSourcePolyfill | null>(null)
  const rootService = useAtomValue(rootServiceAtom)
  const {data: paddleData, loading: paddleInstanceLoading} = useAtomValue(paddleAtom)
  const {paddleInstance, monthList, yearList} = paddleData || {}

  const basicSubscription = party?.subscriptionPlan === ISubscriptionPlan.BASIC

  useEffect(() => {
    const fetchToken = async () => {
      const token = await rootService?.authenticationService?.getToken()
      setAuthToken(token)
    }
    if (party) {
      fetchToken()
    }
  }, [party])

  useEffect(() => {
    if (authToken) {
      const eventSourceInitDict = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
      const source = new EventSource(`${config.coreV2?.url}/v2/api/events`, eventSourceInitDict)
      setEventSource(source)

      source.onmessage = event => {
        const jsonEvent = JSON.parse(event.data)
        if (jsonEvent?.kind === ServerSentEvents.SUBSCRIPTION_PLAN_UPGRADED) {
          if (jsonEvent?.payload?.partyId === party?.id) {
            setParty({
              ...party,
              subscriptionPlan: jsonEvent?.payload?.newPlan,
            })
          }
        }
      }
    }
  }, [authToken])

  useEffect(() => {
    return () => {
      if (eventSource) {
        eventSource.close()
      }
    }
  }, [eventSource])

  useEffect(() => {
    setCheckoutLoading(false)
  }, [party?.subscriptionPlan])

  const getPrices = async (cycle: string) => {
    if (!paddleInstance) {
      return
    }

    const itemsList = cycle === 'month' ? monthList : yearList
    setBillingCycle(cycle)

    const request = {
      items: itemsList,
    }

    try {
      const result = await paddleInstance?.PricePreview(request)
      const items = result?.data?.details?.lineItems

      for (const item of items) {
        if (item.price.id === itemsList[products.PRO].priceId) {
          setProPrice(item.formattedTotals.subtotal)
        } else if (item.price.id === itemsList[products.ENTERPRISE].priceId) {
          setEnterprisePrice(item.formattedTotals.subtotal)
        }
      }
    } catch (error) {
      alertStore.addError('Something went wrong please try again later')
    }
  }

  const openCheckout = (items: any) => {
    paddleInstance?.Checkout.open({
      items: [items],
      customData: {
        partyId: party?.id,
        userId: user?.id,
      },
      customer: {
        email: user?.email,
      },
    })
  }

  useEffect(() => {
    if (paddleInstance && (!proPrice || !enterprisePrice)) {
      getPrices(billingCycle)
    }
  }, [paddleInstance])

  useEffect(() => {
    if (paddleInstance) {
      paddleInstance.Update({
        eventCallback: (event: PaddleEventData) => {
          if (event?.data.status === 'completed' && event?.name == 'checkout.completed') {
            setCheckoutLoading(true)
          }
        },
      })
    }
  }, [paddleInstance])

  const tiers: Tier[] = [
    {
      name: 'Pro',
      price: proPrice,
      features: [
        'Full support during onboarding',
        'Up to 10 Consumption point',
        '10 External contract (PPA or Green tariff)',
        'Ideal for 10-50 GWh annual energy consumption',
      ],
      discount: false,
      buttonText: 'Subscribe',
      onClick: () => {
        openCheckout(billingCycle === 'month' ? monthList[products.PRO] : yearList[products.PRO])
      },
    },
    {
      name: 'Enterprise',
      price: enterprisePrice,
      features: [
        'Full support during onboarding',
        'Multiple External contract (PPA or Green tariff)',
        'Customised reporting',
        'Special offers depending on contract length',
        'Ideal for 51+ GWh annual energy consumption',
      ],
      discount: false,
      buttonText: 'Subscribe',
      onClick: () => {
        openCheckout(billingCycle === 'month' ? monthList[products.ENTERPRISE] : yearList[products.ENTERPRISE])
      },
    },
  ]

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    getPrices((event.target as HTMLInputElement).value)
  }

  if (paddleInstanceLoading || !proPrice || !enterprisePrice) {
    return (
      <Page title={translate('Subscription')} description={translate('View subscription')}>
        <Box direction="column" gap={2} align="center">
          <Skeleton variant="text" width="60%" height={40} />
          <Box direction="row" gap={2} justify="center">
            <Skeleton variant="rectangular" width={120} height={40} />
            <Skeleton variant="rectangular" width={120} height={40} />
          </Box>
          <Box direction="row" gap={2} justify="center">
            <Skeleton variant="rectangular" width={300} height={400} />
            <Skeleton variant="rectangular" width={300} height={400} />
          </Box>
        </Box>
      </Page>
    )
  }

  if (checkoutLoading) {
    return (
      <Page title={translate('Subscription')} description={translate('View subscription')}>
        <Box direction="column" gap={2} align="center">
          <Text size="medium" font={theme.font.headings}>
            Waiting for subscription completion
          </Text>
          <Skeleton variant="rectangular" width="80%" height={60} />
        </Box>
      </Page>
    )
  }

  const getSubscriptionView = () => {
    if (!basicSubscription) {
      return (
        <>
          <Text size="xlarge" font={theme.font.headings} bold>
            Current Subscription Plan
          </Text>
          <Box style={{width: '100%', maxWidth: '400px', padding: '2', textAlign: 'center'}}>
            <Text size="large" font={theme.font.headings} bold color={theme.colors.primary}>
              {getPlanName(party?.subscriptionPlan)}
            </Text>
            <Box direction="column" gap={2} margin={{top: 2}}>
              <Text size="medium">Status: Active</Text>
              {/* This will need to have a place to cancel subscription once we have a flow for that as well */}
            </Box>
          </Box>
        </>
      )
    }
    return (
      <>
        <Text size="medium" font={theme.font.headings}>
          You have have basic plan, upgrade to pro or enterprise to unlock more features
        </Text>
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" value={billingCycle} onChange={handleChange}>
          <Box direction="row" gap={2} justify="center">
            <FormControlLabel value="month" control={<Radio />} label="Monthly" />
            <Box direction="row">
              <FormControlLabel style={{marginRight: 0}} value="year" control={<Radio />} label="Yearly" />
              <Text
                style={{
                  border: '1px solid #82fa7a',
                  backgroundColor: theme.colors.primary,
                  height: 'fit-content',
                  borderRadius: '10px',
                  padding: '1px 4px',
                }}
                size="small"
              >
                Save 20%
              </Text>
            </Box>
          </Box>
        </RadioGroup>
        <PricingTiers tiers={tiers} billingCycle={false} />
      </>
    )
  }

  return (
    <Page title={translate('Subscription')} description={translate('View subscription')}>
      <Box
        direction="column"
        gap={2}
        align="center"
        border
        round
        style={{marginLeft: 'auto', marginRight: 'auto'}}
        pad={{horizontal: 4, vertical: 2}}
        height="fit-content"
      >
        {getSubscriptionView()}
      </Box>
    </Page>
  )
}

export default Subscription
