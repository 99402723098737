import React from 'react'
import FormInput from 'components/Form/FormInput'
import useLocalization from 'hooks/useLocalization'
import Box from 'components/Box'
import config from 'config'
import {getCurrentYear} from 'helpers/date'
import FormSelect from 'components/Form/FormSelect'
import FormAttachmentUpload from 'components/Platform/Form/FormAttachmentUpload'
import TextLink from 'components/TextLink'
import {CertificateTech} from 'domain/IRegoCertificate'
import {startCase, toLower} from 'lodash-es'
import {getHumanReadableState} from 'helpers/site'
import {SiteState} from 'domain/ISite'

interface IProps extends React.PropsWithChildren {
  edit?: boolean
}

const TenderRespondForm: React.FC<IProps> = ({edit}) => {
  const {translate} = useLocalization()

  return (
    <Box width="50%" round border pad={2}>
      <FormInput required defaultValue="" name="siteName" label={translate('Site name')} data-cy="name" />
      <FormSelect
        required
        name="technology"
        label={translate('Technology')}
        defaultValue=""
        placeholder={translate('Select technology')}
        options={Object.values(CertificateTech).map(tech => ({
          value: tech,
          label: translate(startCase(toLower(tech))),
        }))}
        data-cy="technology"
      />
      <FormSelect
        required
        name="state"
        label={translate('Status')}
        defaultValue=""
        placeholder={translate('Select status')}
        options={Object.keys(SiteState).map(state => ({
          value: state,
          label: getHumanReadableState(state as SiteState, translate),
        }))}
        data-cy="status"
      />
      <FormInput
        required
        defaultValue=""
        name="price"
        type="number"
        label={translate('Price')}
        suffix="EUR"
        data-cy="price"
      />
      <FormInput
        required
        defaultValue=""
        type="number"
        name="capacityMwac"
        label={translate('Nameplate capacity, MW')}
        data-cy="capacity"
      />
      <FormInput
        required
        defaultValue=""
        name="operationsStart"
        label={translate('Operations start')}
        type="date"
        placeholder={translate('Insert date')}
        data-cy="operations-start"
      />
      {!edit && (
        <>
          <FormAttachmentUpload required name="attachment" label={translate('Upload profile')} />
          <TextLink
            download
            to={`${config.coreV2.url}/v2/api/annual-excel-templates/hourly/download?year=${getCurrentYear() + 1}`}
          >
            {translate('Download example file')}
          </TextLink>
        </>
      )}
    </Box>
  )
}

export default TenderRespondForm
