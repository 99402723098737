import {initializePaddle} from '@paddle/paddle-js'
import config from 'config'
import {atomWithRefresh} from 'jotai/utils'
import {rootServiceAtom} from './general'
import {partyAtom} from './party'
import {loadable} from './utils'

export const paddleAtom = loadable(
  atomWithRefresh(async get => {
    const services = get(rootServiceAtom)
    const party = get(partyAtom)

    const paddleData = await services.v2ContentService.getPaddlePricing(party?.id)

    const paddleInstance = await initializePaddle({
      environment: config.paddle.environment,
      token: paddleData.paddleClientSideToken,
    })

    const monthList = [
      {
        quantity: 1,
        priceId: paddleData.proPlanMonthlyPriceId,
      },
      {
        quantity: 1,
        priceId: paddleData.enterprisePlanMonthlyPriceId,
      },
    ]

    const yearList = [
      {
        quantity: 1,
        priceId: paddleData.proPlanAnnualPriceId,
      },
      {
        quantity: 1,
        priceId: paddleData.enterprisePlanAnnualPriceId,
      },
    ]

    return {
      paddleInstance,
      monthList,
      yearList,
    }
  }),
)
