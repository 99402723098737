import {action, computed, observable} from 'mobx'
import IUser from '../domain/IUser'
import IParty from '../domain/IParty'
import IAuthenticationService from '../services/IAuthenticationService'
import IContentService from '../services/IContentService'
import IPartyMember from '../domain/IPartyMember'
import LocalizationStore from './LocalizationStore'
import config from 'config'
import IV2ContentService from 'services/IV2ContentService'
import {initializeHelpCrunchUser} from 'helpers/user'

export default class ProfileStore {
  private readonly authenticationService: IAuthenticationService
  private readonly contentService: IContentService
  private readonly v2contentService: IV2ContentService

  private readonly localizationStore: LocalizationStore
  @observable private _user: IUser = null
  @observable private partyMembersById: {[key: string]: IPartyMember} = {}
  @observable public selectedPartyMemberId: any = null
  @observable public isLoaded = false
  @observable public showPhoneVerificationModal = false
  @observable public showPhoneNotVerifiedNotification = false

  constructor(
    authenticationService: IAuthenticationService,
    contentService: IContentService,
    v2contentService: IV2ContentService,
    localizationStore: LocalizationStore,
  ) {
    this.authenticationService = authenticationService
    this.contentService = contentService
    this.v2contentService = v2contentService
    this.localizationStore = localizationStore
  }

  @action
  public async load() {
    try {
      this.isLoaded = false
      const user = await this.authenticationService.getAuthenticatedUser()

      if (user) {
        const {profile, memberships: partyMembers} = await this.v2contentService.getDetailedProfile()
        this.localizationStore.changeLocale(profile.langKey || config.language)
        user.language = profile.langKey || config.language
        user.buyerGuideCompletedOn = profile.buyerGuideCompletedOn

        //TODO: REMOVE
        user.emailVerified = true

        this.setUser(user)
        this.setShowPhoneNotVerifiedNotification(!user.phoneNumberVerified)
        this.setPartyMembers(partyMembers)

        const currentPartyMemberId = localStorage.getItem('currentPartyMemberId')
        const partiesWithRoles = partyMembers.filter(partyMember => partyMember.party?.partyRole)

        if (currentPartyMemberId && partiesWithRoles.find(partyMember => partyMember.id === +currentPartyMemberId)) {
          this.selectedPartyMemberId = currentPartyMemberId
        } else {
          if (partyMembers.length === 1) {
            this.selectedPartyMemberId = partyMembers[0]?.id
          } else {
            this.selectedPartyMemberId = partiesWithRoles[0]?.id || partyMembers[0]?.id
          }
        }

        initializeHelpCrunchUser(user?.id, profile, this.party)
      }
      this.isLoaded = true
    } catch (e) {
      const kind = e?.error?.kind
      this.isLoaded = true

      if (kind === 'USER_BY_GUID_NOT_FOUND') {
        const user = await this.authenticationService.getAuthenticatedUser()
        user && this.setUser(user)
      } else {
        throw e
      }
    }
  }

  @computed
  public get user(): IUser {
    return this._user
  }

  @action
  public setUser(value: IUser) {
    this._user = value
  }

  @action
  public async updateUser(value: IUser) {
    await this.contentService.updateProfile(value)
    // the backend should have taken care of updating the external identity provider. We are just refreshing the old values in the token
    this.setUser(await this.authenticationService.getAuthenticatedUser({bypassCache: true}))
  }

  @action
  public setShowPhoneVerificationModal(shouldShow: boolean) {
    this.showPhoneVerificationModal = shouldShow
  }

  @action
  public setShowPhoneNotVerifiedNotification(shouldShow: boolean) {
    this.showPhoneNotVerifiedNotification = shouldShow
  }

  @computed
  public get party(): IParty {
    return this.partyMembership?.party || null
  }

  @computed
  public get partyMembership(): IPartyMember {
    if (!this.selectedPartyMemberId) {
      return null
    }

    return this.partyMembersById[this.selectedPartyMemberId] || null
  }

  @computed
  public get partyMemberships(): IPartyMember[] {
    return Object.values(this.partyMembersById)
  }

  @action
  public setPartyMembers(partyMembers: IPartyMember[]) {
    partyMembers.forEach(partyMember => this.setPartyMember(partyMember))
  }

  @action
  public setPartyMember(partyMember: IPartyMember) {
    this.partyMembersById[partyMember.id] = partyMember
  }
}
