import React from 'react'
import useLocalization from '../../../../hooks/useLocalization'
import useTheme from '../../../../hooks/useTheme'
import ResponsiveGrid from '../../../ResponsiveGrid'
import {ScreenBreakpoint} from '../../../../hooks/useScreenSize'
import Box from '../../../Box'
import Heading from '../../../Heading'
import FormInput from '../../../Form/FormInput'
import IProduct from '../../../../domain/IProduct'
import FormAttachmentUpload from '../../Form/FormAttachmentUpload'
import InnerScroll from '../../../InnerScroll'
import FormTextEditor from '../../../Form/FormTextEditor'

interface IProps extends React.PropsWithChildren {}

const ProductForm: React.FC<IProps> = () => {
  const {translate} = useLocalization()
  const theme = useTheme()

  return (
    <>
      <InnerScroll noRightPad>
        <ResponsiveGrid gap={3} columns={{[ScreenBreakpoint.Medium]: 1, [ScreenBreakpoint.Large]: 2}}>
          <Box pad={2} round border>
            <Heading size="msmall" margin={{bottom: 2}}>
              {translate('Product summary')}
            </Heading>

            <FormInput defaultValue={''} name="name" label={translate('Product name')} required />

            <FormTextEditor defaultValue={''} name="description" label={translate('Description')} />
          </Box>
          <Box pad={2} round border>
            <Heading size="msmall" margin={{bottom: 2}}>
              {translate('Product attachments')}
            </Heading>
            <FormAttachmentUpload label={translate('Product image')} name="image" accept="image/*" />
          </Box>
        </ResponsiveGrid>
      </InnerScroll>
    </>
  )
}

export default ProductForm
