import IAdminCreatePartyMember from 'domain/IAdminCreateUser'
import {TextDocumentType} from 'domain/IDocumentType'
import IOfferPriceCalculationResult from 'domain/IOfferPriceCalculationResult'
import {IPaginateResult, IPaginatedExtendedResult} from 'domain/IPaginateResult'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import IPurchaseContract from 'domain/IPurchaseContract'
import IPurchaseGroup, {IPurchaseGroupBase} from 'domain/IPurchaseGroup'
import {ISignedContractFile} from 'domain/ISignedContractFile'
import ITextDocument from 'domain/ITextDocument'
import IUser from 'domain/IUser'
import dayjs from 'dayjs'
import IAttachment from '../domain/IAttachment'
import IContract, {ContractState, ContractVolumeProfile} from '../domain/IContract'
import IContractItem from '../domain/IContractItem'
import IContractType from '../domain/IContractType'
import IOffer, {IOfferInvite, OfferState} from '../domain/IOffer'
import IOfferRequest from '../domain/IOfferRequest'
import IOfferTextGroup from '../domain/IOfferTextGroup'
import IParty, {PartyRole} from '../domain/IParty'
import IPartyMember, {RepresentationBasis} from '../domain/IPartyMember'
import IProduct, {ProductState} from '../domain/IProduct'
import IProductType, {VolumeType} from '../domain/IProductType'
import ISite, {SiteProductionTechnology} from '../domain/ISite'
import ITimeseriesItem from '../domain/ITimeseriesItem'
import {SellExcessPayload} from '../domain/INewTimeseriesItem'
import {IShoppingCart} from '../store/ShoppingCartStore'
import IContentService, {Interval} from './IContentService'
import LocalStorageDataService from './LocalStorageDataService'
import LocalStorageRelationalDataService from './LocalStorageRelationalDataService'
import {IPasswordFromInvite} from '../domain/IPasswordFromInvite'
import {IContractedVolume, ICoverageOverview, ITimeseriesOverview} from 'domain/Portfolio'
import IProposalDetails from 'domain/IProposalDetails'
import ICertificateOffer from 'domain/ICertificateOffer'
import SellExcessRequest, {IRecommendedExcessPrice} from 'domain/ISellExcessRequest'
import ISellExcessRequest from 'domain/ISellExcessRequest'
import IMatchingScore from 'domain/IMatchingScore'
import ISitesDashboard from 'domain/ISitesDashboard'
import {PriceUnit} from 'domain/IPrice'

export default class LocalContentService implements IContentService {
  private readonly attachments: LocalStorageDataService<IAttachment>
  private readonly contracts: LocalStorageDataService<IContract>
  private readonly purchaseContracts: LocalStorageDataService<IPurchaseContract>
  private readonly products: LocalStorageDataService<IProduct>
  private readonly mandatoryProducts: LocalStorageDataService<IProduct>
  private readonly productTypes: LocalStorageDataService<IProductType>
  private readonly sites: LocalStorageDataService<ISite>
  private readonly offers: LocalStorageDataService<IOffer>
  private readonly parties: LocalStorageDataService<IParty>
  private readonly partyMembers: LocalStorageDataService<IPartyMember>
  private readonly userPartyMemberships: LocalStorageDataService<IPartyMember>
  private readonly timeseries: LocalStorageDataService<ITimeseriesItem>
  private readonly purchaseGroups: LocalStorageRelationalDataService<IPurchaseGroup>

  public constructor() {
    const attachments = new LocalStorageRelationalDataService<IAttachment>('attachments')
    const contracts = new LocalStorageRelationalDataService<IContract>('contracts')
    const purchaseContracts = new LocalStorageRelationalDataService<IPurchaseContract>('purchaseContracts')
    const products = new LocalStorageRelationalDataService<IProduct>('products')
    const mandatoryProducts = new LocalStorageRelationalDataService<IProduct>('mandatoryProducts')
    const productTypes = new LocalStorageRelationalDataService<IProductType>('productTypes')
    const sites = new LocalStorageRelationalDataService<ISite>('sites')
    const offers = new LocalStorageRelationalDataService<IOffer>('offers')
    const parties = new LocalStorageRelationalDataService<IParty>('parties')
    const partyMembers = new LocalStorageRelationalDataService<IPartyMember>('partyMembers')
    const userPartyMemberships = new LocalStorageRelationalDataService<IPartyMember>('userPartyMemberships')
    const timeseries = new LocalStorageRelationalDataService<ITimeseriesItem>('timeseries')
    const purchaseGroups = new LocalStorageRelationalDataService<IPurchaseGroup>('purchaseGroups')

    products.addManyToOneRelation<IAttachment>(attachments, 'image')
    products.addManyToOneRelation<ISite>(sites, 'site', 'products')
    products.addManyToOneRelation<IProductType>(productTypes, 'productType')

    this.attachments = attachments
    this.contracts = contracts
    this.purchaseContracts = purchaseContracts
    this.products = products
    this.mandatoryProducts = mandatoryProducts
    this.productTypes = productTypes
    this.sites = sites
    this.offers = offers
    this.parties = parties
    this.userPartyMemberships = userPartyMemberships
    this.timeseries = timeseries
    this.partyMembers = partyMembers
    this.purchaseGroups = purchaseGroups
  }

  getPorfolioOverview(partyId: any, interval: Interval): Promise<ITimeseriesOverview> {
    throw new Error('Method not implemented.')
  }

  getPorfolioContractedVolumes(partyId: any, interval: Interval): Promise<IContractedVolume> {
    throw new Error('Method not implemented.')
  }

  getCoverageOverview(partyId: any, interval: Interval): Promise<ICoverageOverview> {
    throw new Error('Method not implemented.')
  }

  getPortfolioSitesDashboard(partyId: any, interval: Interval): Promise<ISitesDashboard> {
    throw new Error('Method not implemented.')
  }

  sendContractVerificationCode(contractId: number) {
    throw new Error('Method not implemented.')
  }

  updateProfile(updatedUser: any) {
    throw new Error('Method not implemented.')
  }

  public async createPartyForCurrentUser(partyMember: Omit<IPartyMember, 'id'>): Promise<IPartyMember> {
    return this.userPartyMemberships.addItem(partyMember)
  }

  public async getConsumptionData(partyId: any, interval: Interval): Promise<ITimeseriesItem[]> {
    return this.timeseries.getItemsWithMeta<any>(meta => meta.partyId === partyId && meta.interval === interval)
  }

  public async getYearlyConsumptionData(partyId: any, start: Date, end: Date): Promise<ITimeseriesItem[]> {
    return this.timeseries.getItemsWithMeta<any>(meta => meta.partyId === partyId && meta.interval === Interval.YEARLY)
  }

  public async getYearlyPurchaseGroupConsumptionData(
    partyId: any,
    purchaseGroupId: any,
    start: number,
    end: number,
  ): Promise<ITimeseriesItem[]> {
    return this.timeseries.getItems()
  }

  public async getYearlyTimeseriesData(siteId: any, start: Date, end: Date): Promise<ITimeseriesItem[]> {
    return this.timeseries.getItemsWithMeta<any>(meta => meta.siteId === siteId && meta.interval === Interval.YEARLY)
  }

  public async getPurchaseGroupConsumptionData(
    partyId: any,
    purchaseGroup: any,
    interval: Interval,
  ): Promise<ITimeseriesItem[]> {
    return this.timeseries.getItems()
  }

  public async getPurchaseGroupYearlyConsumptionData(
    partyId: any,
    purchaseGroup: any,
    start: Date,
    end: Date,
  ): Promise<ITimeseriesItem[]> {
    return this.timeseries.getItems()
  }

  public async getContractHtml(contractId: any): Promise<string> {
    return `<h1>contract ${contractId}</h1>`
  }

  public async getContractPdf(contractId: any): Promise<Blob> {
    throw new Error('Not implemented')
  }

  public async getContractContractTemplatePdf(contractId: any, templateId: any, partyId: any): Promise<Blob> {
    throw new Error('Not implemented')
  }

  public async getContractContractTemplates(contractId: any, partyId: any): Promise<any> {
    return []
  }

  public async getCurrentUserPartyMemberships(): Promise<IPartyMember[]> {
    const partyMembers = this.userPartyMemberships.getItems()

    partyMembers.forEach(pm => {
      if (!pm.party.partyRole) {
        pm.party.partyRole = PartyRole.BUYER
      }
    })
    this.userPartyMemberships.setItems(partyMembers)

    return partyMembers
  }

  public async getPartyContracts(partyId: any, state?: ContractState): Promise<IContract[]> {
    let contracts = this.contracts.getItems()

    if (state) {
      contracts = contracts.filter(c => c.state === state)
    }

    if (contracts.length === 0) {
      return [
        {
          id: 1,
          state: ContractState.SIGNED,
          signingDate: '2024-01-01',
          validFrom: '2024-01-01',
          validTo: '2024-01-31',
          contractItems: [
            {
              id: 1,
              volumeMwh: 1000.0,
              product: {
                id: 1,
                name: 'Product 1',
                state: ProductState.IN_OPERATION,
                description: '',
                salesStart: '2024-01-01',
                salesEnd: '2024-02-31',
                price: {
                  unit: PriceUnit.GBP_MWH,
                  value: 5.0,
                },
                emissionFactor: 0,
                productType: {
                  id: 1,
                  name: 'Product Type 1',
                  volumeType: VolumeType.USER_SELECT,
                },
              },
              price: {
                unit: PriceUnit.GBP_MWH,
                value: 5.0,
              },
              productPriceValue: 0,
            },
          ],
          contractType: undefined,
          summary: {
            volumeMwh: 1000,
            price: {
              average: 5000,
              added: 0,
              subtracted: 0,
              total: 5000,
              unit: PriceUnit.GBP_MWH,
            },
            pricesByProductType: [],
          },
          volumeMwh: 1000,
          volumeProfile: ContractVolumeProfile.LOAD_FOLLOWING,
        },
      ]
    }

    return contracts
  }

  public async getDraftContractsWithoutOffer(partyId: any): Promise<IContract[]> {
    let contracts = this.contracts.getItems()

    contracts = contracts.filter(c => c.state === ContractState.DRAFT)

    return contracts
  }

  public async getTimeseriesData(siteId: any, interval: Interval): Promise<ITimeseriesItem[]> {
    return this.timeseries.getItems()
  }

  public async getPortfolioMatchingScores(partyId: any): Promise<IMatchingScore> {
    throw new Error('Method not implemented.')
  }

  public async sellCertExcessRequest(partyId: any, payload: SellExcessPayload): Promise<SellExcessRequest> {
    return null
  }

  public async uploadTimeseriesData(siteId: any, csv: File): Promise<ISite> {
    return this.sites.getItem(siteId)
  }

  public async cloneTimeseriesData(siteId: any, cloneSiteId: any): Promise<ISite> {
    return this.sites.getItem(siteId)
  }

  public async getProductsVisibleToParty(): Promise<IProduct[]> {
    return this.products.getItems().map(p => this.extendProduct(p))
  }

  public async getProductVisibleToParty(partyId: any, id: any): Promise<IProduct> {
    return this.extendProduct(this.products.getItem(id))
  }

  public async getProductsManagedByParty(): Promise<IProduct[]> {
    return this.products.getItems()
  }

  public async getProductManagedByParty(partyId: any, id: any): Promise<IProduct> {
    return this.products.getItem(id)
  }

  public async getCertificateProducts(
    partyId: any,
    month: string,
    year: number,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IProduct>> {
    return Promise.resolve({
      data: this.products.getItems(),
      page: page,
      pageSize: size,
      totalElements: 10,
      totalPages: 1,
    })
  }

  public async getCertificateProduct(partyId: any, productId): Promise<IProduct> {
    return this.products.getItem(productId)
  }

  public async getCertificateProposalDetails(partyId: any, body: any, interval: any): Promise<IProposalDetails> {
    return null
  }

  public async getCertificatePurchaseOffer(partyId: any, offerId: any): Promise<ICertificateOffer> {
    return null
  }

  public async getAdminExcessSellRequests(): Promise<IPaginatedExtendedResult<ISellExcessRequest>> {
    return null
  }

  public async getPublicExcessSellRequests(partyId: any): Promise<IPaginatedExtendedResult<ISellExcessRequest>> {
    return null
  }

  public async getRecommendedExcessPrice(
    partyId: any,
    technologyKind: SiteProductionTechnology,
  ): Promise<IRecommendedExcessPrice> {
    return null
  }

  public async acceptExcessSellRequest(sellRequestId: any): Promise<void> {
    return null
  }

  public async declineExcexsSellRequest(sellRequestId: any): Promise<void> {
    return null
  }

  public async saveCertificateOffer(partyId: any, body: any): Promise<IOffer> {
    return this.offers.getItems()[0]
  }

  public async editCertificateOffer(partyId: any, offerId: any, body: any): Promise<IOffer> {
    return this.offers.getItems()[0]
  }

  public async sendCertificateReview(partyId: any, offerId: any): Promise<void> {
    return null
  }

  public async approveCertificateOffer(partyId: any, offerId: any): Promise<void> {
    return null
  }

  public async declineCertificateOffer(partyId: any, offerId: any): Promise<void> {
    return null
  }

  private extendProduct(product: IProduct): IProduct {
    return {
      ...product,
      totalContractedVolumeMwh:
        product.totalContractedVolumeMwh ||
        this.contracts.getItems().reduce((cPrev, cCurrent) => {
          return (
            cPrev +
            cCurrent.contractItems.reduce((ciPrev, ciCurrent) => {
              return (
                ciPrev + (ciCurrent.product?.id.toString() === product.id.toString() ? ciCurrent.volumeMwh || 0 : 0)
              )
            }, 0)
          )
        }, 0),
    }
  }

  public async getDefaultContractType(): Promise<IContractType> {
    const [contractType] = await this.getContractTypes()

    return contractType
  }

  public async getContractTypes(): Promise<IContractType[]> {
    const contractType: IContractType = {
      id: 1,
      name: 'Contract type 1',
      termsAndConditions: null,
    }

    return [contractType]
  }

  public async getProductTypes(): Promise<IProductType[]> {
    return this.productTypes.getItems()
  }

  public async reserveProduction(partyId: any, reserve: IShoppingCart[]): Promise<void> {
    const products = await this.getProductsVisibleToParty()
    const contracts = this.contracts.getItems()
    let contract: IContract = contracts.find(c => c.state === ContractState.DRAFT)
    const product = products.find(p => p.id === 1)

    if (!product) {
      throw new Error('Product does not exist')
    }

    if (!contract) {
      contract = {
        id: Math.random(),
        state: ContractState.DRAFT,
        signingDate: null,
        validFrom: null,
        validTo: null,
        contractItems: [],
        contractType: {
          id: 1,
          name: 'someName',
          termsAndConditions: null,
        },
        summary: {
          volumeMwh: 100,
          price: {
            average: product.price.value,
            added: 0,
            subtracted: 0,
            total: product.price.value,
            unit: product.price.unit,
          },
          pricesByProductType: [],
        },
        volumeMwh: 100,
        volumeProfile: ContractVolumeProfile.LOAD_FOLLOWING,
      }
    }

    let contractItem: IContractItem = contract.contractItems.find(i => i.product.id === 1)

    if (!contractItem) {
      contractItem = {
        id: Math.random(),
        volumeMwh: 100,
        product,
        price: product.price,
        productPriceValue: product.price.value,
      }

      contract.contractItems.push(contractItem)
    }

    this.contracts.setItems([contract, ...contracts.filter(c => c.state !== ContractState.DRAFT)])
  }

  public async savePartyForCurrentUser(partyMember: IPartyMember): Promise<IPartyMember> {
    this.userPartyMemberships.setItems([partyMember])

    return partyMember
  }

  public async signContract(partyId: any, contractId: any): Promise<IContract> {
    const contract = this.contracts.getItem(contractId)

    if (!contract) {
      throw new Error('Contract not found')
    }

    contract.signingDate = dayjs().toISOString()
    contract.state = ContractState.SIGNED

    this.contracts.updateItem(contract)

    return contract
  }

  public async uploadConsumptionProfile(partyId: any, csv: File, name?: string): Promise<void> {
    const [partyMember] = await this.userPartyMemberships.getItems()

    partyMember.party.totalAvgYearlyConsumptionMwh = 1234

    this.userPartyMemberships.updateItem(partyMember)
  }

  public async createProduct(partyId: any, product: IProduct): Promise<IProduct> {
    return this.products.addItem(product)
  }

  public async updateProduct(partyId: any, product: IProduct): Promise<IProduct> {
    return this.products.updateItem(product)
  }

  public async createSite(partyId: any, site: ISite): Promise<ISite> {
    return this.sites.addItem(site)
  }

  public async updateSite(partyId: any, site: ISite): Promise<ISite> {
    return this.sites.updateItem(site)
  }

  public async associatePurchaseGroupToSites(
    partyId: any,
    purchaseGroup: IPurchaseGroupBase,
    siteIds: number[],
  ): Promise<any> {
    // TODO
    throw new Error('Not implemented')
  }

  public async softDeleteSite(partyId: any, siteId: any): Promise<ISite> {
    const site = this.sites.getItem(siteId)
    this.sites.removeItem(siteId)

    return site
  }

  public async confirmUserPhoneNumber(pin: string): Promise<void> {
    //TODO
  }

  public async confirmContractVerificationCode(contractId: number, code: string): Promise<void> {
    //TODO
  }

  public async sendUserPhoneVerification(phone: string): Promise<void> {
    //TODO
  }

  public async createOffer(partyId: any, offer: IOffer): Promise<IOffer> {
    offer.state = OfferState.INDICATIVE_DRAFT
    return this.offers.addItem(offer)
  }

  public async getOffer(partyId: any, id: any): Promise<IOffer> {
    return this.offers.getItem(id)
  }

  public async sendDraftToCustomer(partyId: any, id: any): Promise<any> {
    const item = this.offers.getItem(id)
    item.state = OfferState.INDICATIVE_TO_CUSTOMER
    return this.offers.updateItem(item)
  }

  public async sendBindingToCustomer(partyId: any, id: any): Promise<any> {
    return (this.offers.getItem(id).state = OfferState.INDICATIVE_TO_CUSTOMER)
  }

  public async toBindingDraft(partyId: any, id: any): Promise<any> {
    return (this.offers.getItem(id).state = OfferState.BINDING_DRAFT)
  }

  public async userInterestedInOffer(partyId: any, id: any): Promise<any> {
    return (this.offers.getItem(id).state = OfferState.ACCEPTED)
  }

  public async userNotInterestedInOffer(partyId: any, id: any): Promise<any> {
    return (this.offers.getItem(id).state = OfferState.NOT_INTERESTED)
  }

  public async createManagedParty(asPartyId: any, party: IParty): Promise<IParty> {
    return this.parties.addItem(party)
  }

  public async getPagedOffers(partyId: any): Promise<IPaginateResult<IOffer>> {
    return {
      content: this.offers.getItems(),
      totalPages: 0,
    }
  }

  public async getPagedCustomerOffers(partyId: any): Promise<IPaginateResult<IOffer>> {
    return {
      content: this.offers.getItems(),
      totalPages: 0,
    }
  }

  public async getAllOffers(partyId: any): Promise<IOffer[]> {
    return this.offers.getItems()
  }

  public async getManagedParties(asPartyId: any): Promise<IParty[]> {
    return this.parties.getItems()
  }

  public async getManagedParty(partyId: any): Promise<IParty> {
    return this.parties.getItem(partyId)
  }

  public async getPurchaseGroup(partyId: number, purchaseGroupId: number): Promise<IPurchaseGroup> {
    throw new Error('Not implemented')
  }

  public async getPartyMembers(partyId: any): Promise<IPartyMember[]> {
    // TODO
    throw new Error('Not implemented')
  }

  public async getUserFromInvite(partyId: any): Promise<IUser> {
    throw new Error('Not implemented')
  }

  public async getUserFromOfferInvite(accessCode: any): Promise<IOfferInvite> {
    throw new Error('Not implemented')
  }

  public async resendUserInvite(asPartyId: number, partyMemberId: number): Promise<void> {
    // TODO
    throw new Error('Not implemented')
  }

  public async setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void> {
    // TODO
    throw new Error('Not implemented')
  }

  public async setPasswordFromOfferInvite(
    accessCode: string,
    user: {firstName: string; lastName: string; password: string},
  ): Promise<IOffer> {
    // TODO
    throw new Error('Not implemented')
  }

  public async getPartyMember(partyId: number, partyMemberId: number): Promise<IPartyMember> {
    return this.partyMembers.getItem(partyMemberId) as IPartyMember
  }

  public async createPartyMemberUser(
    partyId: number,
    createPartyMember: IAdminCreatePartyMember,
  ): Promise<IPartyMember> {
    const referencedParty = this.parties.getItem(partyId)
    const newUserID = this.userPartyMemberships.getItems().length + 1
    const newUser = {...createPartyMember.user, newUserID} as IUser
    return this.partyMembers.addItem({
      ...createPartyMember,
      user: newUser,
      representationBasis: RepresentationBasis.MEMBER_OF_BOARD,
      party: referencedParty,
    })
  }

  public async updatePartyMember(partyId: number, partyMember: IPartyMemberUpdateRequest): Promise<IPartyMember> {
    const oldPartyMember = this.partyMembers.getItem(partyMember.id)
    const updated = {...oldPartyMember, partyMember}

    this.partyMembers.updateItem(updated)

    return updated
  }

  public async disablePartyMember(partyId: number, partyMemberId: number): Promise<IPartyMember> {
    const partyMember = this.partyMembers.getItem(partyMemberId)
    const updated = {...partyMember, user: {...partyMember.user, enabled: false}}

    this.partyMembers.updateItem(updated)

    return updated
  }

  public async enablePartyMember(partyId: number, partyMemberId: number): Promise<IPartyMember> {
    const partyMember = this.partyMembers.getItem(partyMemberId)
    const updated = {...partyMember, user: {...partyMember.user, enabled: true}}
    this.partyMembers.updateItem(updated)

    return updated
  }

  public async softDeleteManagedParty(partyId: any): Promise<IParty> {
    const party = this.getManagedParty(partyId)
    this.parties.removeItem(partyId)

    return party
  }

  public async softDeleteManagedPartyOffer(partyId: any, offerId: any): Promise<IOffer> {
    const offer = this.offers.getItem(offerId)
    this.offers.removeItem(offerId)

    return offer
  }

  public async updateOffer(partyId: any, offer: IOffer): Promise<IOffer> {
    return this.offers.updateItem(offer)
  }

  public async getOfferTextGroups(partyId: any, offerId: any): Promise<IOfferTextGroup[]> {
    return []
  }

  public async updateManagedParty(asPartyId: any, party: IParty): Promise<IParty> {
    return this.parties.updateItem(party)
  }

  public async updateContract(partyId: any, contract: IContract): Promise<void> {
    this.contracts.updateItem(contract)
  }

  public async getConsumptionSiteTemplates(partyId: any): Promise<ISite[]> {
    return this.sites.getItems().filter(site => site.isTemplate)
  }

  public async getTextDocument(documentType: TextDocumentType, locale: string): Promise<ITextDocument> {
    return {content: `DOCUMENT FOR ${documentType} with locale ${locale}`} as ITextDocument
  }

  public async getProductionSiteTemplates(partyId: any): Promise<ISite[]> {
    return this.sites.getItems().filter(site => site.isTemplate)
  }

  public async getSites(partyId: any): Promise<ISite[]> {
    return this.sites.getItems()
  }

  public async uploadAttachment(file: File, name?: string): Promise<IAttachment> {
    return this.attachments.addItem({
      fileName: file.name,
      mimeType: file.type,
      size: file.size,
      name: name || file.name,
    })
  }

  public getAttachmentURL(attachment: IAttachment): string {
    return attachment ? attachment.fileName : ''
  }

  public getSignedContractFileURL(file: ISignedContractFile): string {
    return file ? file.fileName : ''
  }

  public async loadMandatoryProducts(contractTypeId: number, partyId: any): Promise<IProduct[]> {
    return this.mandatoryProducts.getItems()
  }

  public async createPurchaseContract(partyId: any, contract: IPurchaseContract): Promise<IPurchaseContract> {
    return this.purchaseContracts.addItem({
      ...contract,
      contractItems: contract.contractItems.map(contractItem => ({
        ...contractItem,
        id: this.contracts.getNextId(),
        product: {
          ...contractItem.product,
          id: this.contracts.getNextId(),
          site: {
            ...contractItem.product.site,
            id: this.contracts.getNextId(),
          },
        },
      })),
    })
  }

  public async getPurchaseContracts(partyId: any): Promise<IPurchaseContract[]> {
    return this.purchaseContracts.getItems()
  }

  public async getSalesContracts(partyId: any): Promise<IPurchaseContract[]> {
    return this.purchaseContracts.getItems()
  }

  public async updatePurchaseContract(partyId: any, contract: IPurchaseContract): Promise<IPurchaseContract> {
    return this.purchaseContracts.updateItem(contract)
  }

  public async getPartyPurchaseGroups(partyId: number): Promise<IPurchaseGroup[]> {
    return this.purchaseGroups.getItems()
  }

  public async getPartyOfferRequests(partyId: number): Promise<IOfferRequest[]> {
    return []
  }

  public async getContractSnapshot(contractId: number, partyId: any): Promise<IContract> {
    return this.contracts.getItem(contractId)
  }

  public async defaultCalculateOfferPrice(offer: IOffer): Promise<IOfferPriceCalculationResult> {
    return {minPrice: 0, maxPrice: 0, price: 0, lgcMinPrice: 0, lgcMaxPrice: 0, lgcPrice: 0}
  }

  public async simpleCalculateOfferPrice(offer: IOffer): Promise<IOfferPriceCalculationResult> {
    return {minPrice: 0, maxPrice: 0, price: 0, lgcMinPrice: 0, lgcMaxPrice: 0, lgcPrice: 0}
  }

  public async uploadSignedContractFiles(contractId: number, files: File[]): Promise<void> {
    throw new Error('Not implemented')
  }

  public async deleteSignedContractFile(contractId: number, fileId: number): Promise<void> {
    throw new Error('Not implemented')
  }

  public async getSignedContractFiles(contractId: number): Promise<ISignedContractFile[]> {
    throw new Error('Not implemented')
  }
}
